import { ReactElement } from 'react';
import { TooltipCellWrapper } from '../TooltipCellWrapper';
import NoData from '../../../../batteryHealth/common/NoData';

export const SocCell = ({ value }: { value?: number }): ReactElement => {
    return value !== undefined ? (
        <TooltipCellWrapper content={`${Math.round(value)} %`} />
    ) : (
        <NoData hasTooltip={false} />
    );
};
