import { AssetsState } from './types';
import {
    ASSETS_LOADING_FAILED,
    ASSETS_LOADING_STARTED,
    ASSETS_LOADING_SUCCESSFUL,
    ASSETS_SELECTED,
    AssetsActions,
    GROUPS_SELECTED,
} from '../../actions/assets/types';

const initialState: AssetsState = {
    assets: [],
    fetching: false,
    selectedAssetIds: [],
    selectedGroupIds: [],
    errorCode: null,
};

const reducer = (state: AssetsState = initialState, action: AssetsActions): AssetsState => {
    switch (action.type) {
        case ASSETS_LOADING_STARTED:
            return {
                ...state,
                fetching: true,
                assets: [],
                errorCode: null,
            };
        case ASSETS_LOADING_SUCCESSFUL:
            return {
                ...state,
                fetching: false,
                assets: action.payload,
                errorCode: null,
            };
        case ASSETS_LOADING_FAILED:
            return {
                ...state,
                fetching: false,
                assets: [],
                errorCode: action.payload,
            };
        case ASSETS_SELECTED:
            return {
                ...state,
                selectedAssetIds: action.payload,
            };
        case GROUPS_SELECTED:
            return {
                ...state,
                selectedGroupIds: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
