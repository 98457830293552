import { useIntl } from 'react-intl';

interface LoadMoreButtonProps {
    hasNextPage: boolean;
    fetchNextPage: Function;
    buttonStyle?: string;
    shouldRender: boolean;
}

export const LoadMoreButton = ({ hasNextPage, fetchNextPage, buttonStyle, shouldRender }: LoadMoreButtonProps) => {
    const { formatMessage } = useIntl();

    if (!shouldRender) {
        return null;
    }

    if (hasNextPage) {
        return (
            <div className="display-flex justify-content-center" data-testid="load-more-button">
                <button type="button" className={`btn btn-link ${buttonStyle}`} onClick={() => fetchNextPage()}>
                    <span className="rioglyph rioglyph-arrow-down" />
                    <span>{formatMessage({ id: 'e4c.overview.events.widget.modal.loadMore' })}</span>
                </button>
            </div>
        );
    }

    return (
        <div className="display-flex justify-content-center">
            {formatMessage({ id: 'e4c.batteryHealth.table.loadMore.everythingIsLoaded' })}
        </div>
    );
};
