import { ProductVersion } from '../../common/vehicles/productVersion';
import { AssetBatteryHealthResponse, BatteryStatus, ProductVersionType } from '../types';
import BatteryStatusInfo from './BatteryStatusInfo';

class AssetBatteryHealth {
    public assetId: string;
    public name: string;
    public productVersion: ProductVersionType;
    public vin: string;
    public batteryStatusInfo: BatteryStatusInfo | null;

    constructor(asset: AssetBatteryHealthResponse) {
        this.assetId = asset.asset_id;
        this.name = asset.name;
        this.productVersion =
            asset.product_version === ProductVersion.EMANAGER_M ? ProductVersion.EMANAGER_M : ProductVersion.EMANAGER_S;
        this.vin = asset.vin;
        this.batteryStatusInfo = asset.battery_status_info ? new BatteryStatusInfo(asset.battery_status_info) : null;
    }

    static createAssetBatteryHealthListGroupedByStatus(): AssetBatteryHealthListGroupedByStatus {
        return Object.values(BatteryStatus).reduce((acc, status) => {
            acc[status] = {
                withBatteryHealthInfoValues: [],
                withoutBatteryHealthInfoValues: [],
            };
            return acc;
        }, {} as AssetBatteryHealthListGroupedByStatus);
    }
}

export type AssetBatteryHealthListGroupedByStatus = {
    [status in BatteryStatus]: {
        withBatteryHealthInfoValues: AssetBatteryHealth[];
        withoutBatteryHealthInfoValues: AssetBatteryHealth[];
    };
};

export default AssetBatteryHealth;
