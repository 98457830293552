export const sortNumbersFunc = ({ a, b }: { a?: number | null; b?: number | null }) => (a && b ? a - b : 0);

export const sortStringFunc = ({ a, b }: { a: string; b: string }) => {
    if (a > b) {
        return 1;
    }
    if (b > a) {
        return -1;
    }
    return 0;
};
