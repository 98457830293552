import ChargingProcess from './ChargingProcess';
import _ from 'lodash';
import { SortDirection } from '../../vehicleCharging/types';
import { PaginationOptions } from '../../common/components/pagination/Pagination';
import { Asset } from '../../common/types';
import { ChargeCycleActivityType } from '../types';

class ChargingProcessList {
    private _processList: ChargingProcess[];
    constructor(processList: ChargingProcess[]) {
        this._processList = processList;
    }

    public get processList(): ChargingProcess[] {
        return this._processList;
    }

    public set processList(value: ChargingProcess[]) {
        this._processList = value;
    }

    public size(): number {
        return this._processList.length;
    }

    public getChargingProcessBy(selectedAssetIds: string[]): ChargingProcessList {
        return new ChargingProcessList(this._processList.filter(process => selectedAssetIds.includes(process.assetId)));
    }
    public orderByProperty = (
        cycles: ChargingProcessList,
        property: string,
        sortDirection: SortDirection = SortDirection.ASCENDING
    ): ChargingProcessList => {
        if (property === 'date' && property) {
            return new ChargingProcessList(_.orderBy(cycles.processList, element => element.startTime, sortDirection));
        }
        return cycles;
    };

    public setAssetNameOnAllProcess(assets: Asset[]): void {
        this._processList.forEach(row => {
            const asset = _.find(assets, { id: row.assetId });
            row.assetName = asset && asset.name ? asset.name : '';
        });
    }

    public filterByAssetId(assetIds: string[]): ChargingProcessList {
        return new ChargingProcessList(this._processList.filter(process => assetIds.includes(process.assetId)));
    }

    public filterByPaginationAndGroupByStartDate(pagination: PaginationOptions): {
        [x: string]: ChargingProcessList;
    } {
        const chargingProcessListGroupedByStartDate = this.createGroupsByStartDate();
        const offset = pagination.pageSize * (pagination.pageNumber - 1);
        const filteredKeys = _.drop(_.keys(chargingProcessListGroupedByStartDate), offset).slice(
            0,
            pagination.pageSize
        );
        const filteredRows: { [p: string]: ChargingProcessList } = {};
        filteredKeys.forEach(key => (filteredRows[key] = chargingProcessListGroupedByStartDate[key]));
        return filteredRows;
    }

    private createGroupsByStartDate(): {
        [x: string]: ChargingProcessList;
    } {
        return _.mapValues(
            _.groupBy(this._processList, 'startDate'),
            clist => new ChargingProcessList(clist.map(process => process))
        );
    }

    public getSumEnergyConsumption = (): number => {
        let total = 0;
        let cycle: ChargingProcess;
        for (cycle of this._processList) {
            if (cycle.totalEnergyConsumptionKwh && cycle.type === 'DISCHARGING') {
                total = cycle.totalEnergyConsumptionKwh + total;
            }
        }
        return total;
    };

    public getSumEnergyAdded = (): number => {
        let total = 0;
        let cycle: ChargingProcess;
        for (cycle of this._processList) {
            if (cycle.energyAddedKwh && cycle.type === 'CHARGING') {
                total += cycle.energyAddedKwh;
            }
        }
        return total;
    };

    public getMaxCurrent = (): number => {
        return (
            _.first(
                this._processList
                    .map(p => p.batteryCurrents?.max)
                    .filter(_.isNumber)
                    .sort((a, b) => b - a)
            ) || 0
        );
    };

    public getMaxVoltage = (): number => {
        return (
            _.first(
                this._processList
                    .map(p => p.batteryVoltage?.max)
                    .filter(_.isNumber)
                    .sort((a, b) => b - a)
            ) || 0
        );
    };

    public getMaximumTemperature = (): number | null => {
        return (
            _.first(
                this._processList
                    .map(p => p.temperatures?.max)
                    .filter(_.isNumber)
                    .sort((a, b) => b - a)
            ) || null
        );
    };

    public getNumberOfVehicles = (): number => {
        const quantityOfVehicles: string[] = [];
        let cycle: ChargingProcess;
        for (cycle of this._processList) {
            if (!quantityOfVehicles.includes(cycle.assetId)) {
                quantityOfVehicles.push(cycle.assetId);
            }
        }
        return quantityOfVehicles.length;
    };

    public getTotalOfBatteryTemperatureWarnings = (): number => {
        let totalWarning = 0;
        let cycle: ChargingProcess;
        for (cycle of this._processList) {
            if (cycle.maxBatteryTemperatureWarningC) {
                totalWarning++;
            }
            if (cycle.minBatteryTemperatureWarningC) {
                totalWarning++;
            }
        }
        return totalWarning;
    };

    public getSumMileages = (): number => {
        return _.sumBy(this._processList, 'mileage');
    };

    public getSumAggregatedValues = (): number => {
        let total = 0;
        let cycle: ChargingProcess;
        for (cycle of this._processList) {
            total =
                cycle.type === ChargeCycleActivityType.CHARGING
                    ? total
                    : (cycle.amsEnergyConsumptionKwh ?? 0) +
                      (cycle.auxEnergyConsumptionKwh ?? 0) +
                      (cycle.powertrainEnergyConsumptionKwh ?? 0) +
                      total;
        }
        return Math.round(total);
    };
}

export default ChargingProcessList;
