import { ThunkDispatch } from 'redux-thunk';
import { AssetsState } from '../../reducers/assets/types';
import { AssetsPropertiesFromDispatch, AssetsPropertiesFromState } from './types';
import { AccessToken, getUserAccount } from '../../../../configuration';
import { assetsSelected, fetchAssets, groupsSelected } from '../../actions/assets/Assets.actions';
import { State } from '../../../../types';
import {
    getFetching,
    getAssets,
    getSelectedAssetIds,
    getAssetsErrorCode,
    getSelectedGroupIds,
} from '../../selectors/assets/Assets.selectors';
import { getTags, getFetchingTags, getTagsErrorCode } from '../../selectors/tags/Tags.selectors';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Assets from '../../components/assets/Assets';
import { fetchTags } from '../../actions/tags/Tags.actions';
import { TagsState } from '../../reducers/tags/types';
import { getElectricVehicles, getFetchingElectricVehicles } from '../../selectors/vehicles/Vehicles.selectors';
import { AssetsSelectedAction, AssetsThunkAction, GroupsSelectedAction } from '../../actions/assets/types';
import { TagsThunkAction } from '../../actions/tags/types';

const mapDispatchToProps = (
    dispatch: ThunkDispatch<AssetsState | TagsState, undefined, any>
): AssetsPropertiesFromDispatch => {
    return {
        fetchAssets: (accessToken: AccessToken): AssetsThunkAction<void> => dispatch(fetchAssets(accessToken)),
        fetchTags: (accessToken: AccessToken): TagsThunkAction<void> => dispatch(fetchTags(accessToken)),
        selectAssets: (assetIds: string[]): AssetsSelectedAction => dispatch(assetsSelected(assetIds)),
        selectGroups: (groupIds: string[]): GroupsSelectedAction => dispatch(groupsSelected(groupIds)),
    };
};

const mapStateToProps = (state: State): AssetsPropertiesFromState => {
    return {
        accountId: getUserAccount(state),
        assets: getAssets(state),
        assetsErrorCode: getAssetsErrorCode(state),
        electricVehicles: getElectricVehicles(state),
        fetching: getFetching(state),
        fetchingElectricVehicles: getFetchingElectricVehicles(state),
        fetchingTags: getFetchingTags(state),
        selectedAssetIds: getSelectedAssetIds(state),
        selectedGroupIds: getSelectedGroupIds(state),
        tags: getTags(state),
        tagsErrorCode: getTagsErrorCode(state),
    };
};

const AssetsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Assets));

export default AssetsContainer;
