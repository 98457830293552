import { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import { generatePath, matchPath, useHistory, useLocation, useParams } from 'react-router';
import AssetBatteryHealth from '../Models/AssetBatteryHealth';
import BookNow from '../../common/components/Tables/BookNow/BookNow';
import SohElement from './SohElement';
import TableSohStatus from './TableSohStatus';
import VehicleIdentification from '../../common/components/Tables/VehicleIdentification/VehicleIdentification';
import { ProductVersion } from '../../common/vehicles/productVersion';
import { FeatureToggles } from '../../../../configuration/featureToggle/featureToggles';
import { useFeatureToggle } from '../../common/hooks/useFeatureToggle';
import { BATTERY_HEALTH_DETAILS_PATH } from '../../common/constants/paths/paths';
import NoData from '../common/NoData';

const TableContent = ({
    data,
    totalNumberRowsLoaded,
}: {
    data: AssetBatteryHealth[];
    totalNumberRowsLoaded: number;
}) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [activeRow, setActiveRow] = useState<string | null>(null);
    const { value: isEnabled } = useFeatureToggle(FeatureToggles.BATTERY_ANALYTICS_UC2);
    const { value: isHideSohEnabled } = useFeatureToggle(FeatureToggles.HIDE_SOH_VALUES);
    const { assetId: paramsAssetId } = useParams<{ assetId: string }>();

    const onSelectRow = (assetId: string) => {
        setActiveRow(assetId);
        history.push(generatePath(BATTERY_HEALTH_DETAILS_PATH, { assetId }));
    };

    useEffect(() => {
        if (matchPath(pathname, { path: BATTERY_HEALTH_DETAILS_PATH })) {
            setActiveRow(paramsAssetId);
        } else {
            setActiveRow(null);
        }
    }, [paramsAssetId, pathname]);

    return (
        <tbody data-testid="table-content">
            {data.reduce((rows, value, index) => {
                if (totalNumberRowsLoaded >= index + 1) {
                    rows.push(
                        <TableRow key={value.assetId} value={value} activeRow={activeRow} onClick={onSelectRow}>
                            <td>
                                <VehicleIdentification name={value.name} productVersion={value.productVersion} />
                            </td>
                            <td>
                                {isHideSohEnabled ? (
                                    <NoData tooltip="e4c.batteryHealth.sohMaintenance.message" />
                                ) : (
                                    <TableSohStatus
                                        status={value.batteryStatusInfo?.status}
                                        productVersion={value.productVersion}
                                        percentage={value.batteryStatusInfo?.percentage}
                                    />
                                )}
                            </td>
                            <td>
                                <div className="display-flex flex-row justify-content-between align-items-center">
                                    {isHideSohEnabled ? (
                                        <NoData tooltip="e4c.batteryHealth.sohMaintenance.message" />
                                    ) : (
                                        <SohElement
                                            productVersion={value.productVersion}
                                            content={
                                                value.batteryStatusInfo?.percentage
                                                    ? `${value.batteryStatusInfo?.percentage} %`
                                                    : null
                                            }
                                        />
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className="display-flex flex-row justify-content-between align-items-center">
                                    <SohElement
                                        productVersion={value.productVersion}
                                        content={
                                            value.batteryStatusInfo?.fullChargeCapacity
                                                ? `${value.batteryStatusInfo?.fullChargeCapacity} kWh`
                                                : null
                                        }
                                    />
                                    {!isEnabled || value.productVersion === ProductVersion.EMANAGER_S ? (
                                        <div className="margin-right-20">
                                            <BookNow assetId={value.assetId} productVersion={value.productVersion} />
                                        </div>
                                    ) : (
                                        <span className="rioglyph rioglyph-chevron-right text-color-primary" />
                                    )}
                                </div>
                            </td>
                        </TableRow>
                    );
                }
                return rows;
            }, [] as ReactElement[])}
        </tbody>
    );
};

const TableRow = ({
    value,
    activeRow,
    onClick,
    children,
}: {
    value: AssetBatteryHealth;
    activeRow: string | null;
    onClick: Function;
    children: ReactElement[];
}) => {
    const { assetId, productVersion } = value;
    const { value: isEnabled } = useFeatureToggle(FeatureToggles.BATTERY_ANALYTICS_UC2);

    if (!isEnabled || productVersion === ProductVersion.EMANAGER_S) {
        return <tr role="table-content-row">{children}</tr>;
    }

    const className = classNames({
        active: activeRow === assetId,
        'cursor-pointer': true,
    });

    return (
        <tr role="table-content-row" className={className} onClick={() => onClick(assetId)}>
            {children}
        </tr>
    );
};

export default TableContent;
