import { ProductVersion } from '../common/vehicles/productVersion';

export enum BatteryStatus {
    CRITICAL = 'CRITICAL',
    WARNING = 'WARNING',
    OK = 'OK',
}

export type StatusType = BatteryStatus.OK | BatteryStatus.WARNING | BatteryStatus.CRITICAL;

export type ProductVersionType = ProductVersion.EMANAGER_S | ProductVersion.EMANAGER_M;

type BatteryStatusInfo = {
    status: StatusType;
    percentage: number;
    outdated: boolean;
    lastUpdatedAt: Date;
};

export type AssetBatteryHealth = {
    assetId: string;
    name: string;
    productVersion: ProductVersionType;
    vin: string;
    batteryStatusInfo?: BatteryStatusInfo;
};

export type BatteryStatusInfoResponse = {
    status: string;
    percentage: number | null;
    outdated: boolean;
    last_updated_at: string;
    full_charge_capacity: number | null;
};

export type AssetBatteryHealthResponse = {
    asset_id: string;
    name: string;
    product_version: string;
    vin: string;
    battery_status_info?: BatteryStatusInfoResponse;
};

export type BatteryStatisticsResponse = {
    ok: number;
    critical: number;
    warning: number;
    no_data: number;
};

export enum SortByType {
    percentage = 'percentage',
    fullChargeCapacity = 'fullChargeCapacity',
}

export enum SortColumn {
    percentage = 'percentage',
    status = 'status',
    fullChargeCapacity = 'fullChargeCapacity',
}

export type BatteryHistoryResponse = {
    date_at: string;
    status: BatteryStatus;
    percentage: number;
    full_charge_capacity?: number;
};

export type BatteryHistory = {
    dateAt: string;
    status: BatteryStatus;
    percentage: number;
    fullChargeCapacity?: number;
};
