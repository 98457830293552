import { BatteryStatisticsResponse } from '../types';

class BatteryStatistics {
    public ok: number;
    public warning: number;
    public critical: number;
    public noData: number;
    constructor(batteryStatistics: BatteryStatisticsResponse) {
        this.ok = batteryStatistics.ok;
        this.warning = batteryStatistics.warning;
        this.critical = batteryStatistics.critical;
        this.noData = batteryStatistics.no_data;
    }
}

export default BatteryStatistics;
