import React, { ReactElement } from 'react';
import { AssetsProperties } from './types';
import { RouteComponentProps } from 'react-router';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import SpinnerInfoBox from '@rio-cloud/rio-uikit/Spinner';
import VehiclesContainer from '../../vehicleCharging/containers/Vehicles.container';
import { FormattedMessage } from 'react-intl';
import AssetTreeSidebar from '../../common/components/AssetTreeSidebar/AssetTreeSidebar';
import ErrorState from '../../common/components/states/ErrorState';

const Assets = (props: AssetsProperties & RouteComponentProps): JSX.Element => {
    const {
        assetsErrorCode,
        tagsErrorCode,
        fetching,
        fetchingTags,
        assets,
        tags,
        selectAssets,
        electricVehicles,
        selectGroups,
        selectedGroupIds,
        selectedAssetIds,
    } = props;

    const isError = assetsErrorCode || tagsErrorCode;

    return (
        <React.Fragment>
            <ApplicationLayout.Sidebar>
                {fetching || fetchingTags ? (
                    <FormattedMessage id={'e4c.spinner.loading'}>
                        {(loadingText): ReactElement => <SpinnerInfoBox text={loadingText.toString()} />}
                    </FormattedMessage>
                ) : (
                    <React.Fragment>
                        {!isError && (
                            <AssetTreeSidebar
                                assets={assets}
                                electricVehicles={electricVehicles}
                                hasMultiselect={true}
                                onSelectAssets={selectAssets}
                                onSelectGroups={selectGroups}
                                selectedAssetIds={selectedAssetIds}
                                selectedGroupIds={selectedGroupIds}
                                tags={tags}
                            />
                        )}
                    </React.Fragment>
                )}
            </ApplicationLayout.Sidebar>
            {isError ? (
                <ApplicationLayout.Body>
                    <ErrorState stateProps={{ type: 'general' }} />
                </ApplicationLayout.Body>
            ) : (
                <VehiclesContainer />
            )}
        </React.Fragment>
    );
};

export default Assets;
