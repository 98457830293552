import { Asset } from '../../types';

export const findAssetsFromSelectedGroups = (selectedGroups: string[], allAssets: Asset[]): string[] => {
    const assetsFound: string[] = [];
    selectedGroups.forEach(groupId => {
        assetsFound.push(
            ...allAssets.filter(asset => asset.groupIds && asset.groupIds.includes(groupId)).map(asset => asset.id)
        );
    });
    return assetsFound;
};

export const filterSelectedAssetsFromSelectedGroups = (
    selectedAssets: string[],
    selectedGroups: string[],
    allAssets: Asset[]
): string[] => {
    return selectedAssets.filter(selectedAssetId => {
        const assetFound = allAssets.find(asset => asset.id === selectedAssetId);
        if (assetFound && assetFound.groupIds) {
            const intersection = selectedGroups.filter(
                groupId => assetFound.groupIds && assetFound.groupIds.includes(groupId)
            );
            return !(intersection && intersection.length > 0);
        } else {
            return false;
        }
    });
};
