import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Moment } from 'moment';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import HistoryUpsell from '../HistoryUpsell';
import { LeftContent } from './LeftContent';
import { RightContent } from './RightContent';
import { HistoryContentEnum } from '../types';
import { HistoryDownloadAlert } from '../HistoryDownloadAlert';
import { getCookie, setCookie } from '../../../common/utilities/cookies';
import { getUsername } from '../../../../../configuration';
import { useFeatureToggle } from '../../../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../../configuration/featureToggle/featureToggles';

interface HistoryToolbarProps {
    changeSelectedRangeDate: (startValue: any, endValue: any) => void;
    defaultEndValue: Moment | undefined;
    defaultStartValue: Moment | undefined;
    handleSelectedContentChange: (selection: HistoryContentEnum) => void;
    hasMinimalDatenset: boolean;
    hasMBooking: boolean;
    selectedContent: HistoryContentEnum;
}

export const HistoryToolbar = ({
    changeSelectedRangeDate,
    defaultEndValue,
    defaultStartValue,
    handleSelectedContentChange,
    hasMinimalDatenset,
    hasMBooking,
    selectedContent,
}: HistoryToolbarProps) => {
    const username = useSelector(state => getUsername(state));
    const [isDownloadAlertVisible, setIsDownloadAlertVisible] = useState<boolean>();
    const { value: isRedesignDownload } = useFeatureToggle(FeatureToggles.REDESIGN_DOWNLOAD);

    useEffect(() => {
        if (isRedesignDownload) {
            const cookie = getCookie(`historyDownloadAlert-${username}`);
            if (!cookie) {
                setIsDownloadAlertVisible(true);
                const timeout = setTimeout(() => {
                    setCookie({ key: `historyDownloadAlert-${username}`, value: 'true', expireDate: 1 });
                    setIsDownloadAlertVisible(false);
                }, 5000);

                return () => clearTimeout(timeout);
            }
        }
        return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRedesignDownload]);

    return (
        <>
            <div className="margin-bottom-15">
                <HistoryUpsell hasMBooking={hasMBooking} hasMinimalDataset={hasMinimalDatenset} />
            </div>
            <TableToolbar>
                <div className="display-flex justify-content-center">
                    <HistoryDownloadAlert
                        isVisible={isDownloadAlertVisible}
                        handleClose={() => setIsDownloadAlertVisible(false)}
                    />
                </div>
                <div className=" display-flex table-toolbar-container">
                    {hasMBooking && (
                        <LeftContent
                            changeSelectedRangeDate={changeSelectedRangeDate}
                            defaultEndValue={defaultEndValue}
                            defaultStartValue={defaultStartValue}
                            handleSelectedContentChange={handleSelectedContentChange}
                            selectedContent={selectedContent}
                        />
                    )}
                    <RightContent />
                </div>
            </TableToolbar>
        </>
    );
};
