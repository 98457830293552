import { config } from '../../../../../config';

interface UpsellButtonProps {
    btnLink?: boolean;
    resourceId?: string[];
    productSKU?: ProductSKU;
}

export enum ProductSKU {
    eManagerM = 'MAN-SKU00000050',
    minimalDataSet = 'MAN-SKU00000061',
}

export const TARGET_LEVEL = '&level=20';
export const PATTERN = 'PRODUCT_SKU';
export const BASE_URL = config.upsellurl;

const UpsellButton = ({ btnLink, resourceId, productSKU }: UpsellButtonProps) => {
    let urlWithConfig = BASE_URL;

    if (btnLink) {
        urlWithConfig = `${urlWithConfig}&textOnly`;
    }

    if (resourceId) {
        urlWithConfig = `${urlWithConfig}&resourceIds=${resourceId.join(',')}`;
    }

    if (!productSKU || productSKU === ProductSKU.eManagerM) {
        urlWithConfig = `${urlWithConfig}${TARGET_LEVEL}`;
    }

    const url = urlWithConfig?.replace(PATTERN, productSKU ?? ProductSKU.eManagerM);
    return <iframe data-testid="upsell" className="buyButton" src={url}></iframe>;
};

export default UpsellButton;
