import { FormattedMessage } from 'react-intl';
import NoData from '@rio-cloud/rio-uikit/NoData';
import { BatteryHistory } from '../types';
import { SohStatus } from '../common/SohStatus';

export const HistoricalGraphTooltip = ({ active, payload }: { active?: boolean; payload?: any }) => {
    if (active && payload && payload.length && payload[0].payload) {
        const { dateAt, percentage, status, fullChargeCapacity } = payload[0].payload as BatteryHistory;
        return (
            <div
                className="border border-width-1 border-color-light bg-white padding-x-10 padding-y-15"
                data-testid="historical-graph-tooltip"
            >
                <div>{dateAt}</div>
                <hr className="margin-y-5" />
                <div className="display-flex">
                    <FormattedMessage id="e4c.batteryHealth.tableHeader.stateOfHealthStatus" />:
                    <div className="padding-left-10">
                        <SohStatus status={status} />
                    </div>
                </div>
                <div>
                    <FormattedMessage id="e4c.batteryHealth.tableHeader.stateOfHealthPercentage" />:
                    <span className="text-medium padding-left-10">{percentage}%</span>
                </div>
                <div className="display-flex">
                    <FormattedMessage id="e4c.batteryHealth.tableHeader.fullChargeCapacity" />:
                    <div className="padding-left-10">
                        {fullChargeCapacity ? (
                            <span className="text-medium">{fullChargeCapacity} kWh</span>
                        ) : (
                            <NoData className="text-size-14" text="No data" />
                        )}
                    </div>
                </div>
            </div>
        );
    }
    return null;
};
