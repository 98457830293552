import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getUserAccount } from '../../../../configuration';
import { State } from '../../../../types';
import {
    getAssets,
    getAssetsErrorCode,
    getSelectedAssetIds,
    getSelectedGroupIds,
} from '../../selectors/assets/Assets.selectors';
import { getTags, getTagsErrorCode } from '../../selectors/tags/Tags.selectors';
import { getElectricVehicles } from '../../selectors/vehicles/Vehicles.selectors';
import { HistoryPropertiesFromDispatch, HistoryPropertiesFromState } from './types';
import HistoryComponent from '../pages/HistoryComponent';
import { VehiclesState } from '../../reducers/vehicles/types';
import { AssetsState } from '../../reducers/assets/types';
import {
    getChargingProcesses,
    getChargingProcessesErrorCode,
    getFetchingForHistoryTab,
    getHistorySortBy,
    getHistorySortDir,
    getSelectedHistoryContent,
    hasMBooking,
    hasMinimalDatenset,
} from '../selectors';
import { fetchVehicleChargingProcesses, historyTableSortChanged, selectedHistoryContentChanged } from '../actions';
import { SortDirection } from '../../vehicleCharging/types';
import { HistoryContentEnum } from '../components/types';
import { assetsSelected, groupsSelected } from '../../actions/assets/Assets.actions';
import { VehiclesHistoryActions, VehiclesHistoryThunkAction } from '../types';
import { AssetsSelectedAction, GroupsSelectedAction } from '../../actions/assets/types';

const mapDispatchToProps = (
    dispatch: ThunkDispatch<VehiclesState | AssetsState, undefined, any>
): HistoryPropertiesFromDispatch => {
    return {
        fetchVehicleChargingProcesses: (startDate?: any, endDate?: any): VehiclesHistoryThunkAction<void, {}> =>
            dispatch(fetchVehicleChargingProcesses(startDate, endDate)),
        handleHistoryTableSortChange: (sortDir: SortDirection, sortBy: string): VehiclesHistoryActions =>
            dispatch(historyTableSortChanged(sortDir, sortBy)),
        handleSelectedHistoryContentChange: (selection: HistoryContentEnum): VehiclesHistoryActions =>
            dispatch(selectedHistoryContentChanged(selection)),
        selectAssets: (assetIds: string[]): AssetsSelectedAction => dispatch(assetsSelected(assetIds)),
        selectGroups: (groupIds: string[]): GroupsSelectedAction => dispatch(groupsSelected(groupIds)),
    };
};

const mapStateToProps = (state: State): HistoryPropertiesFromState => {
    return {
        accountId: getUserAccount(state),
        assets: getAssets(state),
        assetsErrorCode: getAssetsErrorCode(state),
        chargingProcesses: getChargingProcesses(state),
        chargingProcessesErrorCode: getChargingProcessesErrorCode(state),
        electricVehicles: getElectricVehicles(state),
        isFetchingForHistoryTab: getFetchingForHistoryTab(state),
        hasMinimalDatenset: hasMinimalDatenset(state),
        historySortBy: getHistorySortBy(state),
        historySortDir: getHistorySortDir(state),
        selectedAssetIds: getSelectedAssetIds(state),
        selectedHistoryContent: getSelectedHistoryContent(state),
        selectedGroupIds: getSelectedGroupIds(state),
        tags: getTags(state),
        tagsErrorCode: getTagsErrorCode(state),
        hasMBooking: hasMBooking(state),
    };
};

const HistoryContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryComponent));

export default HistoryContainer;
