import { AssetBatteryHealthResponse, BatteryStatus, SortByType, SortColumn } from '../types';
import { sortNumbersFunc, sortStringFunc } from '../utils';
import AssetBatteryHealth, { AssetBatteryHealthListGroupedByStatus } from './AssetBatteryHealth';

class AssetBatteryHealthList {
    public assetBatteryHealthList: AssetBatteryHealth[];

    constructor(assets: AssetBatteryHealthResponse[]) {
        this.assetBatteryHealthList = assets.map(item => new AssetBatteryHealth(item));
        this.assetBatteryHealthList = this.sortAssetBatteryHealth(this.assetBatteryHealthList, true);
    }

    public handleSort(data: AssetBatteryHealth[], sortBy = SortColumn.status, asc = true) {
        let sortedData;
        switch (sortBy) {
            case SortColumn.fullChargeCapacity:
                sortedData = this.sortAssetBatteryHealth(data, asc, SortByType.fullChargeCapacity);
                break;
            default:
                sortedData = this.sortAssetBatteryHealth(data, asc);
        }
        return sortedData;
    }

    private sortAssetBatteryHealth(data: AssetBatteryHealth[], asc: boolean, sortBy = SortByType.percentage) {
        const batteryStatus = asc ? Object.values(BatteryStatus) : Object.values(BatteryStatus).reverse();
        const [withBatteryInfoData, withoutBatteryInfoData] = this.groupAssetBatteryHealth(data, sortBy);

        const result = batteryStatus.reduce((sortedData, status) => {
            const withData = withBatteryInfoData[status];
            const withSortValuePresent = this.sortByType(withData.withBatteryHealthInfoValues, asc, sortBy);
            const withoutSortValuePresent = this.sortByName(withData.withoutBatteryHealthInfoValues, asc);
            const sortedResult = [...withSortValuePresent, ...withoutSortValuePresent];

            return [...sortedData, ...sortedResult];
        }, [] as AssetBatteryHealth[]);
        const withoutData = this.sortByName(withoutBatteryInfoData, asc);
        return [...result, ...withoutData];
    }

    private groupAssetBatteryHealth(
        data: AssetBatteryHealth[],
        sortBy = SortByType.percentage
    ): [AssetBatteryHealthListGroupedByStatus, AssetBatteryHealth[]] {
        const [withBatteryInfoData, withoutBatteryInfoData] = data.reduce(
            ([withData, withoutData], item) => {
                const batteryStatusInfo = item.batteryStatusInfo;
                if (batteryStatusInfo?.[sortBy]) {
                    withData[batteryStatusInfo.status].withBatteryHealthInfoValues.push(item);
                } else if (batteryStatusInfo) {
                    withData[batteryStatusInfo.status].withoutBatteryHealthInfoValues.push(item);
                } else {
                    withoutData.push(item);
                }

                return [withData, withoutData];
            },
            [
                AssetBatteryHealth.createAssetBatteryHealthListGroupedByStatus() as AssetBatteryHealthListGroupedByStatus,
                [] as AssetBatteryHealth[],
            ]
        );

        return [withBatteryInfoData, withoutBatteryInfoData];
    }

    private sortByName(data: AssetBatteryHealth[], asc: boolean) {
        const sortedData = data.sort((a: AssetBatteryHealth, b: AssetBatteryHealth) =>
            sortStringFunc({ a: a.name, b: b.name })
        );
        return asc ? sortedData : sortedData.reverse();
    }

    private sortByType(data: AssetBatteryHealth[], asc: boolean, sortBy: SortByType) {
        const sortedData = data.sort((a: AssetBatteryHealth, b: AssetBatteryHealth) => {
            const aValue = a.batteryStatusInfo?.[sortBy];
            const bValue = b.batteryStatusInfo?.[sortBy];
            return sortNumbersFunc({ a: aValue, b: bValue });
        });
        return asc ? sortedData : sortedData.reverse();
    }

    public filterBySearchValue(searchValue: string) {
        return this.assetBatteryHealthList.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
    }
}

export default AssetBatteryHealthList;
