import UpsellButton, { ProductSKU } from '../../common/components/upsell/UpSellButton';
import { useIntl } from 'react-intl';

interface CalloutUpsellProps {
    title: string;
    subtitle: string;
    infoUrl: string | undefined;
    productSKU: ProductSKU;
    handleClose: () => void;
}

const CalloutUpsell = ({ title, subtitle, infoUrl, productSKU, handleClose }: CalloutUpsellProps) => {
    const intl = useIntl();
    return (
        <div
            data-testid="callout-upsell"
            className="callout callout-primary padding-top-5 padding-right-5 padding-bottom-10"
        >
            <div className="display-flex justify-content-between">
                <div className="flex-basis-70pct padding-top-10">
                    <div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-5">
                        <span className="text-capitalize margin-0">{intl.formatMessage({ id: title })}</span>
                    </div>
                    <p className="margin-0">{intl.formatMessage({ id: subtitle })}</p>
                </div>
                <div>
                    <div className="flex-basis-30pct">
                        <div className="display-flex flex-column justify-content-between align-items-end">
                            <button
                                role="button-close"
                                type="button"
                                className="btn btn-icon-only close"
                                onClick={handleClose}
                            >
                                <span className="rioglyph rioglyph-remove"></span>
                            </button>
                            <div className="display-flex gap-10 margin-top-15">
                                <div className="display-flex flex-basis-70pct justify-content-end">
                                    <a
                                        className="btn btn-default text-decoration-none"
                                        target="_blank"
                                        href={infoUrl}
                                        rel="noreferrer"
                                    >
                                        <span>{intl.formatMessage({ id: 'e4c.history.upsellBanner.learnMore' })}</span>
                                    </a>
                                </div>
                                <div className="flex-basis-30pct">
                                    <UpsellButton productSKU={productSKU} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalloutUpsell;
