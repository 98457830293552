import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';

const ChartLoader = () => {
    return (
        <div className="display-flex justify-content-end align-items-start">
            <div
                data-testid="chart-loader"
                className="display-flex flex-1-0 justify-content-center align-items-center margin-50"
            >
                <ContentLoader type={ContentLoader.CIRCLE} radius={120} />
            </div>
            <div
                data-testid="chart-captation-loader"
                className="display-flex flex-column justify-content-between width-100 margin-left-auto"
            >
                <ContentLoader className="margin-bottom-5" />
                <ContentLoader className="margin-bottom-5" />
                <ContentLoader className="margin-bottom-5" />
                <ContentLoader className="margin-bottom-5" />
            </div>
        </div>
    );
};

export default ChartLoader;
