import Table from './Table';
import { useSelector } from 'react-redux';
import { State } from '../../../types';
import { getAccessToken } from '../../../configuration';
import { useGetBatteryHealth } from './queries/useGetBatteryHealth';

const TableWrapper = () => {
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const { isLoading, isError, batteryHealthData } = useGetBatteryHealth(accessToken);

    return (
        <div className="margin-top-25 container fluid-large">
            <Table
                data={batteryHealthData?.assetBatteryHealthList}
                isLoading={isLoading}
                isError={isError}
                handleSort={batteryHealthData?.handleSort.bind(batteryHealthData)}
                filterBySearchValue={batteryHealthData?.filterBySearchValue.bind(batteryHealthData)}
            />
        </div>
    );
};

export default TableWrapper;
