import { MainPropertiesFromDispatch, MainPropertiesFromState } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { AssetsState } from '../../reducers/assets/types';
import { TagsState } from '../../reducers/tags/types';
import { AccessToken, getAccessToken, getUserAccount } from '../../../../configuration';
import { fetchAssets } from '../../actions/assets/Assets.actions';
import { fetchTags } from '../../actions/tags/Tags.actions';
import { State } from '../../../../types';
import { getFetching, getAssets, getAssetsErrorCode } from '../../selectors/assets/Assets.selectors';
import { getFetchingTags, getTags, getTagsErrorCode } from '../../selectors/tags/Tags.selectors';
import { withRouter } from 'react-router';
import Main from '../../components/main/Main';
import { connect } from 'react-redux';
import {
    ElectricVehiclesThunkAction,
    fetchElectricVehiclesFromMonitoringService,
    fetchElectricVehiclesFromStateService,
} from '../../vehicleCharging/actions';
import { AssetsThunkAction } from '../../actions/assets/types';
import { TagsThunkAction } from '../../actions/tags/types';
import Api from '../../../../api/types';

const mapDispatchToProps = (
    dispatch: ThunkDispatch<AssetsState | TagsState, undefined, any>
): MainPropertiesFromDispatch => {
    return {
        fetchAssets: (accessToken: AccessToken): AssetsThunkAction<void> => dispatch(fetchAssets(accessToken)),
        fetchTags: (accessToken: AccessToken): TagsThunkAction<void> => dispatch(fetchTags(accessToken)),
        fetchElectricVehiclesFromMonitoringService: (
            accessToken: AccessToken
        ): ElectricVehiclesThunkAction<void, Api> => dispatch(fetchElectricVehiclesFromMonitoringService(accessToken)),
        fetchElectricVehiclesFromStateService: (accessToken: AccessToken): ElectricVehiclesThunkAction<void, Api> =>
            dispatch(fetchElectricVehiclesFromStateService(accessToken)),
    };
};

const mapStateToProps = (state: State): MainPropertiesFromState => {
    return {
        accessToken: getAccessToken(state),
        fetching: getFetching(state),
        assets: getAssets(state),
        fetchingTags: getFetchingTags(state),
        tags: getTags(state),
        accountId: getUserAccount(state),
        assetsErrorCode: getAssetsErrorCode(state),
        tagsErrorCode: getTagsErrorCode(state),
    };
};

const MainContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));

export default MainContainer;
