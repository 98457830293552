import { useIntl } from 'react-intl';
import { BatteryStatus } from '../types';

const statusIcon = (status: BatteryStatus) => {
    if (status === BatteryStatus.CRITICAL) {
        return 'rioglyph-error-sign text-color-danger';
    } else if (status === BatteryStatus.WARNING) {
        return 'rioglyph-dangerousgoods text-color-warning';
    }
    return 'rioglyph-ok text-color-info';
};

const statusLabel = (status: BatteryStatus) => {
    if (status === BatteryStatus.CRITICAL) {
        return 'e4c.overview.events.widget.modal.severity.critical';
    } else if (status === BatteryStatus.WARNING) {
        return 'e4c.confirmationDialog.title.warning';
    }
    return 'e4c.batteryHealth.table.batteryHealthStatusOk';
};

export const SohStatus = ({ status }: { status: BatteryStatus }) => {
    const intl = useIntl();
    return (
        <>
            <span className={`rioglyph ${statusIcon(status)} text-size-14 margin-right-10`}></span>
            <span className="text-size-14">{intl.formatMessage({ id: statusLabel(status) }).toUpperCase()}</span>
        </>
    );
};
