import { camelCase, isObject, transform } from 'lodash';

export const convertObjectKeysToCamelCase = <
    TInput extends object,
    TOutput extends object,
    T extends TInput = TInput,
    Y extends TOutput = TOutput,
>(
    obj: T
): Y =>
    transform(obj, (acc: any, value: any, key: any) => {
        const camelKey = camelCase(key);
        acc[camelKey] = isObject(value) ? convertObjectKeysToCamelCase<T, Y>(value as T) : value;
    });
