import { useQuery } from 'react-query';
import ServicesApi from '../../../../api/Api';
import mappingVehicleChargingFromServer from '../helpers/mappingVehicleChargingFromServer';
import { MonitoringServiceResponse } from '../models/api/monitoringServiceResponse';
import { StateServiceResponse } from '../models/api/stateServiceResponse';
import { VehicleChargingData } from '../models/VehicleChargingData';

const TEN_MINUTES = 300000 * 2;

const queryOptions = (accessToken?: string | null) => ({ refetchInterval: TEN_MINUTES, enabled: !!accessToken });

const useVehicleChargingAPI = (
    refreshTableAfterSaveDetails: boolean,
    accessToken?: string | null
): { isLoading: boolean; isError: boolean; vehicleChargingData: VehicleChargingData[] } => {
    const monitor = useQuery<unknown, Error, MonitoringServiceResponse>(
        ['monitor-vc', refreshTableAfterSaveDetails],
        () => ServicesApi.getMonitoringVehicles(accessToken).then(res => res.json()),
        queryOptions(accessToken)
    );
    const state = useQuery<unknown, Error, StateServiceResponse>(
        ['state-vc', refreshTableAfterSaveDetails],
        () => ServicesApi.getStateVehicles(accessToken).then(res => res.json()),
        queryOptions(accessToken)
    );

    const isLoading = monitor.isLoading || state.isLoading;
    const isError = monitor.isError || state.isError;

    const vehicleChargingData: VehicleChargingData[] = mappingVehicleChargingFromServer(monitor.data, state.data);

    return { isLoading, isError, vehicleChargingData };
};

export default useVehicleChargingAPI;
