import ErrorState from '../../common/components/states/ErrorState';

interface TableErrorStateProps {
    hasData: boolean;
    error: boolean;
}

const TableErrorState = ({ hasData, error }: TableErrorStateProps): JSX.Element | null => {
    if (error) {
        return <ErrorState stateProps={{ type: 'general', withoutBorder: true }} />;
    }
    return !hasData ? (
        <ErrorState stateProps={{ type: 'notfound', withoutBorder: true, message: 'Please refine your search' }} />
    ) : null;
};

export default TableErrorState;
