import { matchPath, useLocation } from 'react-router';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import PieChartWrapper from './PieChartWrapper';
import TableWrapper from './TableWrapper';
import { Sidebar } from './Sidebar/Sidebar';
import { BATTERY_HEALTH_DETAILS_PATH } from '../common/constants/paths/paths';
import { useFeatureToggle } from '../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../configuration/featureToggle/featureToggles';
import { config } from '../../../config';

const BatteryHealth = () => {
    const { pathname } = useLocation();
    const { value: isEnabled } = useFeatureToggle(FeatureToggles.BATTERY_ANALYTICS_UC2);
    const isSidebarOpen = matchPath(pathname, { path: BATTERY_HEALTH_DETAILS_PATH });

    return (
        <>
            {isSidebarOpen && isEnabled && <Sidebar />}
            <ApplicationLayout.Body
                className={'responsive'}
                banner={
                    config.banner.enable && (
                        <ApplicationLayoutBodyBanner>{config.banner.message}</ApplicationLayoutBodyBanner>
                    )
                }
            >
                <PieChartWrapper />
                <TableWrapper />
            </ApplicationLayout.Body>
        </>
    );
};

export default BatteryHealth;
