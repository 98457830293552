import { areWeTestingWithVitest } from './RuntimeHelpers';
import * as jstz from 'jstimezonedetect';

export const determineTimezone = (): string => {
    const tz = jstz.determine();
    // Intl.DateTimeFormat().resolvedOptions().timeZone is not supported in IE
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : tz.name();
    return timezone;
};

export const currentTimezone = areWeTestingWithVitest() ? 'Europe/Berlin' : determineTimezone();
