import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import UserManualPage from '../components/UserManualPage';
import UserManualSection from '../components/UserManualSection';
import detailBatteryHealthPanelModalImage from '../../../../../assets/images/detail-battery-health-panel-modal.png';

const BatteryHealthItem = (): ReactElement => {
    const intl = useIntl();
    const formattedMessageValues = {
        b: (chunks: any) => <b>{chunks}</b>,
        ul: (chunks: any) => <ul>{chunks}</ul>,
        li: (chunks: any) => <li>{chunks}</li>,
    };
    return (
        <UserManualPage
            title={<FormattedMessage id="e4c.userManual.details.batteryHealth" />}
            footer={<FormattedMessage id="e4c.userManual.details.global.onlyAvailable" />}
            dataTestId="battery-health-item"
        >
            <img
                alt={intl.formatMessage({ id: 'e4c.userManual.altText.batteryHealth' })}
                src={detailBatteryHealthPanelModalImage}
                width="959"
                height="322"
            />
            <UserManualSection title={<FormattedMessage id="e4c.userManual.details.batteryHealth.sectionTitle" />}>
                <FormattedMessage
                    id="e4c.userManual.details.batteryHealth.description"
                    values={formattedMessageValues}
                />
            </UserManualSection>
            <UserManualSection title={<FormattedMessage id="e4c.userManual.details.batteryHealth.sectionTitle2" />}>
                <FormattedMessage
                    id="e4c.userManual.details.batteryHealth.description2"
                    values={formattedMessageValues}
                />
                <div className={'margin-left-10 margin-top-25'}>
                    <div>
                        <span className="event-icon rioglyph rioglyph-ok-sign text-color-info float-left" />
                        <div className={'margin-left-25 margin-top-25'}>
                            <FormattedMessage
                                id="e4c.userManual.details.batteryHealth.status1"
                                values={formattedMessageValues}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="event-icon rioglyph rioglyph rioglyph-dangerousgoods text-color-warning float-left" />
                        <div className={'margin-left-25 margin-top-25'}>
                            <FormattedMessage
                                id="e4c.userManual.details.batteryHealth.status2"
                                values={formattedMessageValues}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="event-icon rioglyph rioglyph-error-sign text-color-danger float-left" />
                        <div className={'margin-left-25 margin-top-25'}>
                            <FormattedMessage
                                id="e4c.userManual.details.batteryHealth.status3"
                                values={formattedMessageValues}
                            />
                        </div>
                    </div>
                </div>
            </UserManualSection>
        </UserManualPage>
    );
};
export default BatteryHealthItem;
