import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import ListMenu from '@rio-cloud/rio-uikit/ListMenu';
import SplitDialog from '@rio-cloud/rio-uikit/SplitDialog';
import { UserManualItems } from './UserManualItems';
import InfoItem from './InfoItem';
import { E_MANAGER } from '../../../constants/common';
import { USER_MANUAL } from '../../../constants/paths/paths';
import { removeQueryParam } from '../../../helpers/queryParametersHelper';
import { useFeatureToggle } from '../../../hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../../../configuration/featureToggle/featureToggles';
import { ListMenuItem } from '@rio-cloud/rio-uikit/components/listMenu/ListMenuGroup';

const UserManualModal = (): ReactElement => {
    const history = useHistory();

    const handleClose = (): void => {
        history.push({
            pathname: history.location.pathname,
            search: removeQueryParam(history.location.search, USER_MANUAL),
        });
    };

    const { value: isNewHistoryEnabled } = useFeatureToggle(FeatureToggles.NEW_HISTORY);
    const { value: isBatteryHealthEnabled } = useFeatureToggle(FeatureToggles.BATTERY_HEALTH);
    const { value: isUC2Enabled } = useFeatureToggle(FeatureToggles.BATTERY_ANALYTICS_UC2);
    const { value: isRedesignDownloadEnabled } = useFeatureToggle(FeatureToggles.REDESIGN_DOWNLOAD);

    const disabledMenuItems = [
        ...(!isNewHistoryEnabled ? ['NEW_HISTORY'] : []),
        ...(!isBatteryHealthEnabled ? ['BATTERY_HEALTH'] : []),
        ...(!isUC2Enabled ? ['BATTERY_HEALTH_HISTORICAL'] : []),
        ...(!isRedesignDownloadEnabled ? ['DOWNLOAD_HISTORY'] : []),
    ];

    const menuItems = filterNavItemsByToggle(UserManualItems, disabledMenuItems);

    return (
        <SplitDialog
            onClose={(): void => handleClose()}
            bsSize={'lg'}
            show={history.location.search.includes(USER_MANUAL)}
            title={E_MANAGER}
            leftContent={<ListMenu menuItems={menuItems} groupClassName="padding-left-0 padding-right-10" />}
            rightContent={<InfoItem />}
        />
    );
};

const filterNavItemsByToggle = (menuItems: ListMenuItem[], disabledMenuItems: string[]) =>
    menuItems.map(menuItem => ({
        ...menuItem,
        navItems: menuItem.navItems.filter(navItem => !disabledMenuItems.includes(navItem.key)),
    }));

export default UserManualModal;
