import { ThunkDispatch } from 'redux-thunk';
import { VehiclesState } from '../../reducers/vehicles/types';
import { VehiclesPropertiesFromDispatch, VehiclesPropertiesFromState } from '../../containers/vehicles/types';
import { getAccessToken } from '../../../../configuration';
import { vehiclesTableRowSelected } from '../../actions/vehicles/Vehicles.actions';
import { getVehiclesSelectedRow, getVehiclesLoadingErrorCode } from '../../selectors/vehicles/Vehicles.selectors';
import { State } from '../../../../types';
import { connect } from 'react-redux';
import Vehicles from '../components/Vehicles';
import { getSelectedAssetIds } from '../../selectors/assets/Assets.selectors';
import { VehiclesTableRowSelectedAction } from '../../actions/vehicles/types';

const mapDispatchToProps = (dispatch: ThunkDispatch<VehiclesState, undefined, any>): VehiclesPropertiesFromDispatch => {
    return {
        selectRow: (vehicleId: string | null): VehiclesTableRowSelectedAction =>
            dispatch(vehiclesTableRowSelected(vehicleId)),
    };
};

const mapStateToProps = (state: State): VehiclesPropertiesFromState => {
    return {
        accessToken: getAccessToken(state),
        selectedRow: getVehiclesSelectedRow(state),
        selectedVehicleIds: getSelectedAssetIds(state),
        vehiclesLoadingErrorCode: getVehiclesLoadingErrorCode(state),
    };
};

const VehiclesContainer = connect(mapStateToProps, mapDispatchToProps)(Vehicles);

export default VehiclesContainer;
