import { AccessToken } from '../../../../configuration';
import {
    ASSETS_LOADING_FAILED,
    ASSETS_LOADING_STARTED,
    ASSETS_LOADING_SUCCESSFUL,
    ASSETS_SELECTED,
    AssetsSelectedAction,
    AssetsThunkAction,
    AssetsThunkDispatch,
    GROUPS_SELECTED,
    GroupsSelectedAction,
} from './types';
import { config } from '../../../../config';
import { AssetWithTags } from '../../components/assets/types';
import * as _ from 'lodash';
import { Asset } from '../../common/types';
import { ErrorCodeEnum } from '../../common/enums/ErrorCode';

export const fetchAssets = (accessToken: AccessToken): AssetsThunkAction<void> => {
    return async (dispatch: AssetsThunkDispatch): Promise<void> => {
        dispatch({
            type: ASSETS_LOADING_STARTED,
        });

        const assetsEndpoint = `${config.backend.ASSET_ADMINISTRATION_SERVICE}/assets?embed=(tags)`;
        const response = await fetch(assetsEndpoint, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status === 200) {
            const json = await response.json();

            const assetsWithTags: AssetWithTags[] = json.items;
            const assets: Asset[] = assetsWithTags.map((assetWithTag: any) => {
                return {
                    id: assetWithTag.id,
                    name: assetWithTag.name,
                    type: assetWithTag.type,
                    groupIds: _.get(assetWithTag, '_embedded.tags.items', []).map((item: { id: string }) => item.id),
                };
            });

            dispatch({
                type: ASSETS_LOADING_SUCCESSFUL,
                payload: assets,
            });
        } else {
            dispatch({
                type: ASSETS_LOADING_FAILED,
                payload: ErrorCodeEnum.OTHER,
            });
        }
    };
};

export const assetsSelected = (assetIds: string[]): AssetsSelectedAction => {
    return {
        type: ASSETS_SELECTED,
        payload: assetIds,
    };
};

export const groupsSelected = (groupIds: string[]): GroupsSelectedAction => {
    return {
        type: GROUPS_SELECTED,
        payload: groupIds,
    };
};
