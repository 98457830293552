import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import MaintenanceState from '@rio-cloud/rio-uikit/MaintenanceState';
import Widget from '../common/components/Widget';
import PieChart from './PieChart';
import { useGetBatteryStatistics } from './queries/useGetBatteryHealth';
import { State } from '../../../types';
import { getAccessToken } from '../../../configuration';
import { useFeatureToggle } from '../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../configuration/featureToggle/featureToggles';

const PieChartWrapper = ({ overview }: { overview?: boolean }) => {
    const intl = useIntl();
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const { isLoading, isError, batteryStatistics } = useGetBatteryStatistics(accessToken);
    const { value: isHideSohEnabled } = useFeatureToggle(FeatureToggles.HIDE_SOH_VALUES);

    const batteryHealthPageStyle = !overview ? 'margin-top-25 container fluid-large' : '';
    return (
        <Widget
            className={batteryHealthPageStyle}
            title={intl.formatMessage({ id: 'e4c.batteryHealth.widget.fleetBatteryHealth.title' })}
        >
            {isHideSohEnabled ? (
                <MaintenanceState
                    headline={intl.formatMessage({ id: 'e4c.batteryHealth.sohMaintenance.title' })}
                    message={intl.formatMessage({ id: 'e4c.batteryHealth.sohMaintenance.message' })}
                    outerClassName="border-none"
                    data-testid="maintenance-state"
                />
            ) : (
                <PieChart isLoading={isLoading} data={batteryStatistics} error={isError} />
            )}
        </Widget>
    );
};

export default PieChartWrapper;
