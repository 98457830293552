import { FormattedMessage } from 'react-intl';
import { Entries } from 'type-fest';
import BatteryStatistics from '../Models/BatteryStatistics';

const CHART_STATIC_INFO = {
    ok: {
        name: <FormattedMessage id={'e4c.batteryHealth.widget.fleetBatteryHealth.legendOk'} />,
        color: 'brand-info',
        tooltip: 'e4c.batteryHealth.widget.tooltipOk',
    },
    warning: {
        name: <FormattedMessage id={'e4c.batteryHealth.widget.fleetBatteryHealth.legendWarning'} />,
        color: 'brand-warning',
        tooltip: 'e4c.batteryHealth.widget.tooltipWarning',
    },
    critical: {
        name: <FormattedMessage id={'e4c.batteryHealth.widget.fleetBatteryHealth.legendCritical'} />,
        color: 'brand-danger',
        tooltip: 'e4c.batteryHealth.widget.tooltipCritical',
    },
    noData: {
        name: <FormattedMessage id={'e4c.batteryHealth.widget.fleetBatteryHealth.legendNodata'} />,
        color: 'gray',
        tooltip: 'e4c.batteryHealth.widget.tooltipNodata',
    },
};

export const mapChartData = (data: BatteryStatistics) => {
    const dataEntriesMapped = Object.entries(data) as Entries<BatteryStatistics>;
    return dataEntriesMapped.map(([key, value]) => ({
        name: CHART_STATIC_INFO[key].name,
        color: CHART_STATIC_INFO[key].color,
        tooltip: CHART_STATIC_INFO[key].tooltip,
        totalVehicles: value,
    }));
};
