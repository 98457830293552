import { IntlProvider } from 'react-intl';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { DEFAULT_LOCALE } from '../../../configuration';
import './App.css';
import { AppProperties } from './types';
import MainContainer from '../containers/main/Main.container';
import UserManualModal from '../common/components/Header/userManual/UserManualModal';
import { TimezoneProvider } from '../common/providers/timezone';
import { TokenProvider } from '../common/providers/token';
import Header from '../common/components/Header/Header';
import FeedbackModal from '../common/components/Header/Feedback/FeedbackModal';
import WhatsNewChecker from '../common/components/Header/userManual/WhatsNew/WhatsNewChecker';
import { ConfigCatProvider, createConsoleLogger, DataGovernance, PollingMode } from 'configcat-react';
import { config } from '../../../config';

const App = (props: AppProperties): JSX.Element => {
    const { hideSessionDialog, languageData, showSessionExpired, userLocale } = props;

    const logger = createConsoleLogger(config.configCat.logLevel);

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={languageData}>
            <ConfigCatProvider
                sdkKey={config.configCat.apiKey}
                pollingMode={PollingMode.LazyLoad}
                options={{
                    cacheTimeToLiveSeconds: config.configCat.cacheTimeInSeconds,
                    logger,
                    dataGovernance: DataGovernance.EuOnly,
                }}
            >
                <TimezoneProvider>
                    <ApplicationLayout className={'BatteryManagement'}>
                        <Header />
                        <TokenProvider accessToken={props.accessToken}>
                            <UserManualModal />
                            <FeedbackModal />
                        </TokenProvider>
                        <NotificationsContainer />
                        <SessionExpiredDialog
                            locale={userLocale}
                            onClose={hideSessionDialog}
                            show={showSessionExpired}
                        />
                        <WhatsNewChecker />
                        <MainContainer />
                    </ApplicationLayout>
                </TimezoneProvider>
            </ConfigCatProvider>
        </IntlProvider>
    );
};

export default App;
