import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';

export const MAX_TABLE_ROWS = 20;

const TableLoader = () => {
    return (
        <tbody data-testid="table-loader">
            {[...Array(MAX_TABLE_ROWS)].map((_, i) => (
                <tr key={i} role="row">
                    <td>
                        <ContentLoader width="75%" />
                    </td>
                    <td>
                        <ContentLoader width="25%" />
                    </td>
                    <td>
                        <ContentLoader width="25%" />
                    </td>
                    <td>
                        <ContentLoader width="25%" />
                    </td>
                </tr>
            ))}
        </tbody>
    );
};

export default TableLoader;
