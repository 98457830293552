import { AccessToken } from '../../../../configuration';
import {
    TagsThunkAction,
    TagsThunkDispatch,
    TAGS_LOADING_STARTED,
    TAGS_LOADING_SUCCESSFUL,
    TAGS_LOADING_FAILED,
} from './types';
import { config } from '../../../../config';
import { ErrorCodeEnum } from '../../common/enums/ErrorCode';

export const fetchTags = (accessToken: AccessToken): TagsThunkAction<void> => {
    return async (dispatch: TagsThunkDispatch): Promise<void> => {
        dispatch({
            type: TAGS_LOADING_STARTED,
        });

        const response = await fetch(`${config.backend.TAG_SERVICE}/tags`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status === 200) {
            const json = await response.json();
            const tags = json.items;
            dispatch({
                type: TAGS_LOADING_SUCCESSFUL,
                payload: tags,
            });
        } else {
            dispatch({
                type: TAGS_LOADING_FAILED,
                payload: ErrorCodeEnum.OTHER,
            });
        }
    };
};
