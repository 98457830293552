import { useIntl } from 'react-intl';
import { CustomTooltip } from '../../../components/shared/CustomTooltip';
import { TooltipCellWrapper } from './TooltipCellWrapper';

const CycleDataHeaderCell = ({ contentId, tooltipId }: { contentId: string; tooltipId?: string }) => {
    const intl = useIntl();

    const renderContent = (
        <TooltipCellWrapper
            content={intl.formatMessage({
                id: contentId,
            })}
        />
    );

    return (
        <th>
            {tooltipId ? (
                <div className="display-flex">
                    {renderContent}
                    <CustomTooltip messageId={tooltipId} iconClassName="cursor-pointer" />
                </div>
            ) : (
                renderContent
            )}
        </th>
    );
};

const CycleDataHeader = () => {
    const tableHeaderItems = [
        {
            contentId: 'e4c.history.vehicle.table.header.energyCharged',
            tooltipId: 'e4c.history.vehicle.table.header.tooltip.energyCharged',
        },
        {
            contentId: 'e4c.history.vehicle.table.header.rangeAdded',
        },
        {
            contentId: 'e4c.history.vehicle.table.header.energyConsumed',
            tooltipId: 'e4c.history.vehicle.table.header.tooltip.energyConsumed',
        },
        {
            contentId: 'e4c.history.vehicle.table.header.rangeDriven',
        },
        {
            contentId: 'e4c.history.vehicle.table.header.consumption',
        },
        {
            contentId: 'e4c.history.vehicle.table.header.socAtStart',
        },
        {
            contentId: 'e4c.history.vehicle.table.header.socAtEnd',
        },
        {
            contentId: 'e4c.history.vehicle.table.header.batteryTemperature',
        },
    ];

    return (
        <thead className="text-color-black height-50" data-testid="table-header">
            <tr>
                <th style={{ width: '15%' }} />
                {tableHeaderItems.map((item, index) => (
                    <CycleDataHeaderCell key={index} contentId={item.contentId} tooltipId={item.tooltipId} />
                ))}
            </tr>
        </thead>
    );
};

export default CycleDataHeader;
