import { ReactElement, useCallback, useEffect } from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import { KpiEnum, OverviewProperties, VehicleStatistics } from './types';
import './Overview.css';
import OverviewTeaser from './teaser/OverviewTeaser';
import BarChartPanel from './barChart/BarChartPanel';
import moment from 'moment';
import UpsellingBarChartPanel from './barChart/UpsellingBarChartPanel';
import ElectricVehicle from '../vehicleCharging/models/ElectricVehicle';
import Widget from '../common/components/Widget';
import VehicleChargingStatus from './overviewWidget/contentWidget/VehicleChargingStatus';
import { LinkVehicleCharging } from './overviewWidget/links/LinkVehicleCharging';
import { useIntl } from 'react-intl';
import { CHARTS } from '../common/constants/common';
import EventsWidget from './overviewWidget/contentWidget/events/EventsWidget';
import { config } from '../../../config';
import { ProductVersion } from '../common/vehicles/productVersion';
import { useFeatureToggle } from '../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../configuration/featureToggle/featureToggles';
import PieChartWrapper from '../batteryHealth/PieChartWrapper';

interface OverviewChartProps {
    productBookings: Record<ProductVersion, number>;
    fetchingVehiclesStatistics: boolean;
    vehicles: ElectricVehicle[];
    vehiclesCount: number;
    vehiclesStatistics: VehicleStatistics[];
    localStoragePrefix: string;
    initiallySelectedKpi: KpiEnum;
}

const OverviewChart = ({
    productBookings,
    fetchingVehiclesStatistics,
    vehicles,
    vehiclesCount,
    vehiclesStatistics,
    localStoragePrefix,
    initiallySelectedKpi,
}: OverviewChartProps): ReactElement => {
    return productBookings[ProductVersion.EMANAGER_M] === 0 && !fetchingVehiclesStatistics ? (
        <UpsellingBarChartPanel localStoragePrefix={localStoragePrefix} initiallySelectedKpi={initiallySelectedKpi} />
    ) : (
        <BarChartPanel
            defaultStartValue={moment().startOf('month')}
            defaultEndValue={moment()}
            fetchingVehiclesStatistics={fetchingVehiclesStatistics}
            initiallySelectedKpi={initiallySelectedKpi}
            localStoragePrefix={localStoragePrefix}
            vehicles={vehicles}
            vehiclesCount={vehiclesCount}
            vehiclesStatistics={vehiclesStatistics}
        />
    );
};

const Overview = ({
    fetchElectricVehicles,
    fetchVehiclesStatistics,
    electricVehicles,
    vehiclesStatistics,
    fetchingVehiclesStatistics,
    fetchingElectricVehicles,
    electricVehiclesFromServices,
    accessToken,
}: OverviewProperties): ReactElement => {
    const intl = useIntl();
    const { value: isBatteryHealthEnabled } = useFeatureToggle(FeatureToggles.BATTERY_HEALTH);
    const { value: showOverviewCharts } = useFeatureToggle(FeatureToggles.SHOW_OVERVIEW_CHARTS);

    const fetchVehiclesAndStatistics = useCallback(() => {
        fetchElectricVehicles(accessToken);
        if (showOverviewCharts) {
            fetchVehiclesStatistics(accessToken);
        }
    }, [accessToken, fetchElectricVehicles, fetchVehiclesStatistics, showOverviewCharts]);

    useEffect(() => {
        fetchVehiclesAndStatistics();
    }, [fetchVehiclesAndStatistics]);

    const productBookings = electricVehiclesFromServices.productBookings;
    const vehiclesDrivingCount = electricVehiclesFromServices.drivingCountForMVehicles;
    const vehiclesReadyToDriveCount = electricVehiclesFromServices.readyToDriveCount;

    const bookingsM = fetchingElectricVehicles ? 0 : productBookings[ProductVersion.EMANAGER_M];
    const bookingsS = fetchingElectricVehicles ? 0 : productBookings[ProductVersion.EMANAGER_S];

    return (
        <ApplicationLayout.Body
            className={'responsive'}
            banner={
                config.banner.enable && (
                    <ApplicationLayoutBodyBanner>{config.banner.message}</ApplicationLayoutBodyBanner>
                )
            }
        >
            <div className="container-fluid fluid-large">
                <OverviewTeaser
                    fetchingElectricVehicles={fetchingElectricVehicles}
                    vehiclesCount={electricVehiclesFromServices.size}
                    mVehicleCount={bookingsM}
                    sVehicleCount={bookingsS}
                />
                <div className="display-grid grid-cols-2 gap-20">
                    <div className="display-flex flex-column gap-20">
                        <Widget
                            title={intl.formatMessage({ id: 'e4c.overview.widget.title' })}
                            link={<LinkVehicleCharging />}
                        >
                            <VehicleChargingStatus
                                vehicles={electricVehicles}
                                isFetching={fetchingElectricVehicles}
                                hasMProductBooking={Boolean(bookingsM)}
                                vehiclesDrivingCount={vehiclesDrivingCount}
                                vehiclesReadyToDriveCount={vehiclesReadyToDriveCount}
                            />
                        </Widget>
                        {isBatteryHealthEnabled && <PieChartWrapper overview />}
                    </div>
                    <EventsWidget />
                </div>
                {showOverviewCharts && (
                    <div className="row padding-top-25">
                        <div className="col-xs-12 col-md-6">
                            <OverviewChart
                                productBookings={productBookings}
                                fetchingVehiclesStatistics={fetchingVehiclesStatistics}
                                vehicles={electricVehicles}
                                vehiclesCount={bookingsM}
                                vehiclesStatistics={vehiclesStatistics}
                                localStoragePrefix={CHARTS.left}
                                initiallySelectedKpi={KpiEnum.AV_DRIVING_CONSUMPTION}
                            />
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <OverviewChart
                                productBookings={productBookings}
                                fetchingVehiclesStatistics={fetchingVehiclesStatistics}
                                vehicles={electricVehicles}
                                vehiclesCount={bookingsM}
                                vehiclesStatistics={vehiclesStatistics}
                                localStoragePrefix={CHARTS.right}
                                initiallySelectedKpi={KpiEnum.AV_RECUPERATION}
                            />
                        </div>
                    </div>
                )}
            </div>
        </ApplicationLayout.Body>
    );
};

export default Overview;
