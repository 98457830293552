/* eslint-disable react-hooks/exhaustive-deps */
// we should discuss the relevance of this warning
import { ReactElement, useEffect, useState } from 'react';
import { Moment } from 'moment';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/fp/isEmpty';
import AssetTree from '@rio-cloud/rio-uikit/AssetTree';
import Tree, { TreeGroup } from '@rio-cloud/rio-uikit/Tree';
import TreeCategory from '@rio-cloud/rio-uikit/TreeCategory';
import ElectricVehicle from '../../../vehicleCharging/models/ElectricVehicle';
import { Asset } from '../../types';
import { handleStartAndEndDate } from '../../../history/pages/helper/HistoryComponentHelper';
import { filterSelectedAssetsFromSelectedGroups, findAssetsFromSelectedGroups } from './utils';

const CATEGORY_BUS = 'buses';

const SELECTED_GROUPS_QUERY = 'groupIds';
const SELECTED_ASSETS_QUERY = 'assetIds';

const UNASSIGNED_GROUP: TreeGroup = {
    id: 'unassigned',
    name: 'Ungrouped',
    position: 'last',
};

let buses: any[] = [];

interface AssetTreeSidebarProps {
    assets: Asset[];
    defaultEndValue?: Moment | undefined;
    electricVehicles: ElectricVehicle[];
    hasMultiselect: boolean;
    onSelectAssets: (assetIds: string[]) => void;
    onSelectGroups: (groupIds: string[]) => void;
    selectedGroups?: string[];
    selectedAssets?: string[];
    selectedAssetIds: string[];
    selectedGroupIds: string[];
    setDefaultEndValue?: Function;
    setDefaultStartValue?: Function;
    tags: TreeGroup[];
}

const AssetTreeSidebar = (props: AssetTreeSidebarProps): ReactElement => {
    const {
        assets,
        defaultEndValue,
        electricVehicles,
        hasMultiselect,
        onSelectAssets,
        selectedAssetIds,
        setDefaultEndValue,
        setDefaultStartValue,
        onSelectGroups,
        selectedGroupIds,
        tags,
    } = props;
    const [expandedVehicleGroups, setExpandedVehicleGroups] = useState<string[]>([]);

    const [currentCategoryId, setCurrentCategoryId] = useState(CATEGORY_BUS);

    const [isTreeOpen, setIsTreeOpen] = useState(true);

    const history = useHistory();

    const intl = useIntl();

    const getQuery = (): URLSearchParams => {
        return new URLSearchParams(history.location.search);
    };

    const filterAssetGroupIds = (groups: string[] | null | undefined, groupIdsSelected: string[]): boolean => {
        if (groups != null) {
            const selecteds = groups.filter(group => groupIdsSelected.includes(group));
            return selecteds.length > 0;
        }
        return false;
    };

    useEffect(() => {
        const query = getQuery();
        const groupIds: (string | null)[] = [query.get(SELECTED_GROUPS_QUERY)];
        const assetIds: (string | null)[] = [query.get(SELECTED_ASSETS_QUERY)];
        if (groupIds && groupIds[0]) {
            const splitGroupIds = groupIds[0].split(',');
            onSelectGroups(splitGroupIds);
            const assetsIds = assets
                .filter(asset => filterAssetGroupIds(asset.groupIds, splitGroupIds))
                .map(asset => asset.id);
            onSelectAssets(assetsIds);
        }
        if (assetIds && assetIds[0]) {
            const splitAssetIds = assetIds[0].split(',');
            onSelectAssets(splitAssetIds);
        }
    }, [history]);

    useEffect(() => {
        if (selectedGroupIds.length > 0 && assets.length > 0) {
            let assetIdsFromGroups = findAssetsFromSelectedGroups(selectedGroupIds, assets);
            assetIdsFromGroups = [...selectedAssetIds, ...assetIdsFromGroups];
            assetIdsFromGroups = assetIdsFromGroups.filter((a, b) => assetIdsFromGroups.indexOf(a) === b);
            assetIdsFromGroups = assetIdsFromGroups.filter(assetId =>
                electricVehicles.some(electricVehicle => electricVehicle.assetId === assetId)
            );
            onSelectAssets(assetIdsFromGroups);
        }
    }, [assets, electricVehicles]);

    useEffect(() => {
        updateUrl(
            SELECTED_ASSETS_QUERY,
            filterSelectedAssetsFromSelectedGroups(selectedAssetIds, selectedGroupIds, assets)
        );
    }, [selectedAssetIds]);

    useEffect(() => {
        updateUrl(SELECTED_GROUPS_QUERY, selectedGroupIds);
    }, [selectedGroupIds]);

    const updateUrl = (key: string, values: (string | null)[]): void => {
        const query = getQuery();
        query.delete(key);
        if (values && values.length !== 0) {
            query.append(key, values.toString());
        }
        history.push({ search: query.toString() });
        checkDateRangeFirstClick();
    };

    const handleSelectVehicle = ({ items, groups }: any): void => {
        const selectedVehiclesSet = new Set<string>();
        items.forEach((vehicleId: string) => selectedVehiclesSet.add(vehicleId));
        if (!isEmpty(groups)) {
            buses.forEach(bus => {
                if (bus.groupIds && electricVehicles.find((vehicle: ElectricVehicle) => vehicle.assetId === bus.id)) {
                    bus.groupIds.forEach((groupId: string) => {
                        if (groups.includes(groupId)) {
                            selectedVehiclesSet.add(bus.id);
                        }
                    });
                }
            });
        }
        onSelectAssets(Array.from<string>(selectedVehiclesSet.values()));
        if (onSelectGroups) {
            onSelectGroups(groups);
        }
    };

    const checkDateRangeFirstClick = (): void => {
        if (
            (selectedGroupIds.length !== 0 || selectedAssetIds.length !== 0) &&
            defaultEndValue === undefined &&
            setDefaultEndValue &&
            setDefaultStartValue
        ) {
            handleStartAndEndDate(
                history,
                (startDate: Moment, endDate: Moment) => {
                    setDefaultEndValue(endDate);
                    setDefaultStartValue(startDate);
                },
                () => {}
            );
        }
    };

    const handleToggleTree = (): void => {
        setIsTreeOpen(!isTreeOpen);
    };

    let busGroups = [UNASSIGNED_GROUP];

    if (!isEmpty(tags)) {
        busGroups = [...busGroups, ...tags];
    }
    if (!isEmpty(assets)) {
        buses = assets;
        buses.forEach(bus => {
            if (bus.groupIds && bus.groupIds.length === 0) {
                bus.groupIds = [UNASSIGNED_GROUP.id];
            }
        });
    }

    const assetsWithClass = buses.map(obj => ({
        ...obj,
        className: electricVehicles.find((vehicle: ElectricVehicle) => vehicle.assetId === obj.id)
            ? ''
            : 'pointer-events-none opacity-50',
    }));

    const treeCategory = (
        <TreeCategory
            hasSelection={!isEmpty(selectedAssetIds) || !isEmpty(selectedGroupIds)}
            icon={'rioglyph-truck'}
            id={CATEGORY_BUS}
            key={CATEGORY_BUS}
            label={intl.formatMessage({ id: 'e4c.assetTree.menuIcon.tooltip' })}
        >
            <Tree
                expandedGroups={expandedVehicleGroups}
                groups={busGroups}
                hasMultiselect={hasMultiselect}
                items={assetsWithClass}
                onExpandGroupsChange={setExpandedVehicleGroups}
                onSelectionChange={handleSelectVehicle}
                searchPlaceholder={intl.formatMessage({ id: 'e4c.assetTree.search.placeholder' })}
                selectedGroups={selectedGroupIds}
                selectedItems={selectedAssetIds}
            />
        </TreeCategory>
    );

    return (
        <AssetTree
            bordered={false}
            currentCategoryId={currentCategoryId}
            isOpen={isTreeOpen}
            maxWidth={450}
            minWidth={300}
            onCategoryChange={setCurrentCategoryId}
            onToggleTree={handleToggleTree}
            useOffscreen={false}
        >
            {treeCategory}
        </AssetTree>
    );
};

export default AssetTreeSidebar;
