import { combineReducers, Reducer } from 'redux';
import { History } from 'history';
import { configReducer, langReducer, loginReducer /* , tokenHandlingReducer*/ } from './configuration';
import tokenHandlingReducer from './configuration/tokenHandling/reducer'; // TODO make import from ./configuration work
import appReducer from './features/app/reducers/App.reducers';
import vehiclesReducer from './features/app/reducers/vehicles/Vehicles.reducers';
import assetsReducer from './features/app/reducers/assets/Assets.reducers';
import tagsReducer from './features/app/reducers/tags/Tags.reducers';
import vehicleDetailsReducer from './features/app/vehicleCharging/reducers/details/VehicleDetails.reducers';
import { connectRouter } from 'connected-react-router';
import vehiclesStatisticsReducer from './features/app/overview/reducers/statistics/VehiclesStatistics.reducers';
import vehiclesHistoryReducer from './features/app/history/reducers';
import electricVehiclesReducer from './features/app/vehicleCharging/reducers';

export const rootReducer = (history: History): Reducer =>
    combineReducers({
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        router: connectRouter(history),
        tokenHandling: tokenHandlingReducer,
        vehicles: vehiclesReducer,
        assets: assetsReducer,
        tags: tagsReducer,
        vehicleDetails: vehicleDetailsReducer,
        vehiclesStatistics: vehiclesStatisticsReducer,
        vehiclesHistory: vehiclesHistoryReducer,
        electricVehicles: electricVehiclesReducer,
    });
