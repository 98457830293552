import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { TreeGroup } from '@rio-cloud/rio-uikit/Tree';
import { State } from '../../../../types';
import { ErrorCodeEnum } from '../../common/enums/ErrorCode';

export const TAGS_LOADING_STARTED = 'TAGS_LOADING_STARTED';
export const TAGS_LOADING_SUCCESSFUL = 'TAGS_LOADING_SUCCESSFUL';
export const TAGS_LOADING_FAILED = 'TAGS_LOADING_FAILED';

export interface TagsLoadingStartedAction {
    type: typeof TAGS_LOADING_STARTED;
}

export interface TagsLoadingSuccessfulAction {
    type: typeof TAGS_LOADING_SUCCESSFUL;
    payload: TreeGroup[];
}

export interface TagsLoadingFailedAction {
    type: typeof TAGS_LOADING_FAILED;
    payload: ErrorCodeEnum | null;
}

export type TagsActions = TagsLoadingStartedAction | TagsLoadingSuccessfulAction | TagsLoadingFailedAction;

export type TagsThunkAction<R> = ThunkAction<R, State, undefined, TagsActions>;
export type TagsThunkDispatch = ThunkDispatch<State, undefined, TagsActions>;
