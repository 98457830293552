import { VehicleDetailsProps, Weekday } from './types';
import moment from 'moment-timezone';
import ElectricVehicle from '../../../models/ElectricVehicle';
import { Vehicle } from '../../../types';
import VehicleDetailsConfiguration from '../VehicleDetailsConfiguration/VehicleDetailsConfiguration';
import VehicleDetailsConfigurationFactory from '../VehicleDetailsConfiguration/VehicleDetailsConfigurationFactory';
import VehicleDetailsFooter from '../VehicleDetailsFooter/VehicleDetailsFooter';
import VehicleDetailsFooterFactory from '../VehicleDetailsFooter/VehicleDetailsFooterFactory';
import { currentTimezone } from '../../../../utils/timezone';

export const days: string[] = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export const weekdaysArray: Weekday[] = [
    Weekday.SUNDAY,
    Weekday.MONDAY,
    Weekday.TUESDAY,
    Weekday.WEDNESDAY,
    Weekday.THURSDAY,
    Weekday.FRIDAY,
    Weekday.SATURDAY,
];

export const getTimeHrs = (time: string): number => {
    return new Date(`1970-01-01T${time}`).getHours();
};

export const getTimeMins = (time: string): number => {
    return new Date(`1970-01-01T${time}`).getMinutes();
};

export const convertDepartureTime = (departureTime: string, zoneId: string): string => {
    const [hours, minutes] = departureTime.split(':').map(Number);
    const formattedDepartureTime = moment.tz({ hours, minutes }, zoneId);
    const convertedDateTime = formattedDepartureTime.tz(currentTimezone).format('HH:mm');

    return convertedDateTime;
};

export const convertDateToDayMonthYear = (date: Date, locale: string): string => {
    return date.toLocaleDateString(locale, { day: '2-digit', month: '2-digit', year: 'numeric' });
};

export const departureTimes = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
];

export const targetStateOfChargeOptions = [40, 50, 60, 70, 80, 90, 100];

export const DEPARTURE_TIMES_PREVIEW_LENGTH = 2;

export const createVehicleDetailsFooter = (
    vehicle: ElectricVehicle | undefined,
    selectedVehicle: Vehicle | null,
    vehicleDetailsProps: VehicleDetailsProps,
    onSidebarClose: () => void,
    hasUnsavedChanges: () => boolean
): VehicleDetailsFooter | null => {
    let vehicleDetailsFooter: VehicleDetailsFooter | null = null;
    if (vehicle && selectedVehicle) {
        const vehicleDetailsFooterFactory = new VehicleDetailsFooterFactory(
            vehicle,
            selectedVehicle,
            vehicleDetailsProps,
            onSidebarClose,
            hasUnsavedChanges
        );
        vehicleDetailsFooter = vehicleDetailsFooterFactory.createFooter();
    }
    return vehicleDetailsFooter;
};

export const createVehicleDetailsConfiguration = (
    toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void,
    vehicle: ElectricVehicle | undefined,
    selectedVehicle: Vehicle | null,
    vehicleDetailsProps: VehicleDetailsProps,
    isReadOnlyUser: boolean
): VehicleDetailsConfiguration | null => {
    let vehicleDetailsConfiguration: VehicleDetailsConfiguration | null = null;
    if (vehicle && selectedVehicle) {
        const vehicleDetailsConfigurationFactory = new VehicleDetailsConfigurationFactory(
            toggleUnsavedVehicleChanges,
            vehicle,
            selectedVehicle,
            vehicleDetailsProps,
            isReadOnlyUser
        );
        vehicleDetailsConfiguration = vehicleDetailsConfigurationFactory.createConfiguration();
    }
    return vehicleDetailsConfiguration;
};
