import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Moment } from 'moment/moment';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import HistoryUpsell from '../components/HistoryUpsell';
import DateRangePicker from '../../common/components/DateRangePicker/DateRangePicker';
import { RightContent } from '../components/tableToolbar/RightContent';
import { VehicleListLoader } from './VehicleExpander/VehicleListLoader';
import ErrorState from '../../common/components/states/ErrorState';
import { VehicleListDateWrapper } from './VehicleExpander/VehicleListDateWrapper';
import { LoadMoreButton } from '../../common/components/LoadMore/LoadMoreButton';
import { useGetChargeStats } from './queries/useHistory';
import { State } from '../../../../types';
import { getAccessToken } from '../../../../configuration';
import { hasMBooking, hasMinimalDatenset } from '../selectors';
import { HistoryDownloadButton } from '../../common/features/NewHistoryDownload/HistoryDownloadButton';
import { useFeatureToggle } from '../../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../configuration/featureToggle/featureToggles';

interface NewHistoryBodyProps {
    selectedAssetIds: string[];
    defaultStartValue: Moment | undefined;
    defaultEndValue: Moment | undefined;
    manageDateChange: Function;
    isOnlyGroupsWithoutAssetsSelected: boolean;
}

export const NewHistoryBody = ({
    selectedAssetIds,
    defaultStartValue,
    defaultEndValue,
    manageDateChange,
    isOnlyGroupsWithoutAssetsSelected,
}: NewHistoryBodyProps) => {
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const hasMBookingResult = useSelector((state: State) => hasMBooking(state));
    const hasMinimalDatensetResult = useSelector((state: State) => hasMinimalDatenset(state));
    const { value: isRedesignDownload } = useFeatureToggle(FeatureToggles.REDESIGN_DOWNLOAD);
    const { isLoading, result, isError, hasNextPage, fetchNextPage, hasPages } = useGetChargeStats(
        accessToken,
        selectedAssetIds,
        defaultStartValue,
        defaultEndValue,
        isOnlyGroupsWithoutAssetsSelected
    );

    const getHistoryContent = () => {
        if (isLoading) {
            return <VehicleListLoader />;
        }
        if (isError) {
            return <ErrorState stateProps={{ type: 'general' }} verticalAlign="80" />;
        }
        if (result.length === 0 || isOnlyGroupsWithoutAssetsSelected) {
            return (
                <ErrorState
                    stateProps={{
                        type: 'notfound',
                        message: <FormattedMessage id="e4c.history.notFoundState.message" />,
                    }}
                    verticalAlign="80"
                />
            );
        }
        return (
            <>
                {result.map(item => (
                    <VehicleListDateWrapper assetsChargeStatsByDate={item} key={item.date} />
                ))}
                <LoadMoreButton hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} shouldRender={hasPages} />
            </>
        );
    };

    return (
        <>
            <div className="margin-bottom-15">
                <HistoryUpsell hasMBooking={hasMBookingResult} hasMinimalDataset={hasMinimalDatensetResult} />
            </div>
            <TableToolbar>
                <div className="table-toolbar-container">
                    {hasMBookingResult && (
                        <div data-testid="toolbar-left-content" className="table-toolbar-group-left">
                            <div className="width-250 min-width-350">
                                <DateRangePicker
                                    defaultStartValue={defaultStartValue}
                                    defaultEndValue={defaultEndValue}
                                    changeSelectedRangeDate={(start, end) => {
                                        manageDateChange(start, end);
                                    }}
                                />
                            </div>
                            {isRedesignDownload && (
                                <div className="padding-left-10">
                                    <HistoryDownloadButton startDate={defaultStartValue} endDate={defaultEndValue} />
                                </div>
                            )}
                        </div>
                    )}
                    <RightContent />
                </div>
            </TableToolbar>
            {getHistoryContent()}
        </>
    );
};
