import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { State } from '../../../../types';
import ApplicationLayoutSkeleton from '../../common/components/applicatonLayout';
import AssetTreeSidebar from '../../common/components/AssetTreeSidebar/AssetTreeSidebar';
import {
    getAssets,
    getAssetsErrorCode,
    getSelectedAssetIds,
    getSelectedGroupIds,
} from '../../selectors/assets/Assets.selectors';
import { FAKE_ASSETS, TEAM_ACCOUNT } from '../components/fakeAssets';
import { getUserAccount } from '../../../../configuration';
import { getTags, getTagsErrorCode } from '../../selectors/tags/Tags.selectors';
import { getElectricVehicles } from '../../selectors/vehicles/Vehicles.selectors';
import { assetsSelected, groupsSelected } from '../../actions/assets/Assets.actions';
import { NewHistoryBody } from './NewHistoryBody';
import { handleStartAndEndDate, updateUrl } from '../pages/helper/HistoryComponentHelper';
import { useFullFeatureToggleByUsername } from '../../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../configuration/featureToggle/featureToggles';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../utils/googleTagManager';

const NewHistory = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [defaultStartValue, setDefaultStartValue] = useState<Moment | undefined>();
    const [defaultEndValue, setDefaultEndValue] = useState<Moment | undefined>();
    const tags = useSelector((state: State) => getTags(state));
    const assets = useSelector((state: State) => getAssets(state));
    const accountId = useSelector((state: State) => getUserAccount(state));
    const tagsErrorCode = useSelector((state: State) => getTagsErrorCode(state));
    const assetsErrorCode = useSelector((state: State) => getAssetsErrorCode(state));
    const electricVehicles = useSelector((state: State) => getElectricVehicles(state));
    const selectedAssetIds = useSelector((state: State) => getSelectedAssetIds(state));
    const selectedGroupIds = useSelector((state: State) => getSelectedGroupIds(state));
    const isSidebarError = assetsErrorCode || tagsErrorCode;
    const { variationId } = useFullFeatureToggleByUsername(FeatureToggles.NEW_HISTORY_PRE_SELECTED);

    useEffect(() => {
        if (variationId !== undefined) {
            gtmUtil.pushGaEvent(
                createGoogleAnalyticsEvent(TrackingCategory.HISTORY_TAB, TrackingAction.VISIT_NEW_HISTORY, variationId)
            );
        }
    }, [variationId]);

    const isOnlyGroupsWithoutAssetsSelected = useMemo(
        () => selectedGroupIds.length > 0 && selectedAssetIds.length === 0,
        [selectedGroupIds.length, selectedAssetIds.length]
    );

    const assetTreeAssets = useMemo(
        () => (accountId !== TEAM_ACCOUNT ? assets : assets.concat(FAKE_ASSETS)),
        [accountId, assets]
    );

    const manageDateChange = useCallback(
        (start: Moment, end: Moment) => {
            updateUrl(history, start, end);
            setDefaultEndValue(end);
            setDefaultStartValue(start);
            gtmUtil.pushGaEvent(
                createGoogleAnalyticsEvent(
                    TrackingCategory.HISTORY_TAB,
                    TrackingAction.CHANGE_DATES,
                    `${start} - ${end}`
                )
            );
        },
        [history]
    );

    useEffect(() => {
        handleStartAndEndDate(
            history,
            (startDate: Moment, endDate: Moment) => {
                setDefaultEndValue(endDate);
                setDefaultStartValue(startDate);
            },
            () => {}
        );
    }, [history]);

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);
        if (!searchParams.has('startDate') && !searchParams.has('endDate')) {
            const startDate = startOfDay(subDays(new Date(), 4));
            const endDate = endOfDay(new Date());
            manageDateChange(moment(startDate), moment(endDate));
        }
    }, [history.location.search, manageDateChange]);

    return (
        <ApplicationLayoutSkeleton
            isLoading={false}
            sidebarLeftContent={{
                content: !isSidebarError && (
                    <AssetTreeSidebar
                        assets={assetTreeAssets}
                        defaultEndValue={defaultEndValue}
                        electricVehicles={electricVehicles}
                        hasMultiselect={true}
                        onSelectAssets={assetIds => {
                            dispatch(assetsSelected(assetIds));
                        }}
                        onSelectGroups={groupIds => {
                            dispatch(groupsSelected(groupIds));
                        }}
                        selectedAssetIds={selectedAssetIds}
                        selectedGroupIds={selectedGroupIds}
                        setDefaultEndValue={setDefaultEndValue}
                        setDefaultStartValue={setDefaultStartValue}
                        tags={tags}
                    />
                ),
            }}
        >
            <NewHistoryBody
                selectedAssetIds={selectedAssetIds}
                defaultStartValue={defaultStartValue}
                defaultEndValue={defaultEndValue}
                manageDateChange={manageDateChange}
                isOnlyGroupsWithoutAssetsSelected={isOnlyGroupsWithoutAssetsSelected}
            />
        </ApplicationLayoutSkeleton>
    );
};

export default NewHistory;
