export const OVERVIEW_FEEDBACK_PATH = '#overview?feedback';
export const FEEDBACK_SEARCH_QUERY = 'feedback';

export const USER_MANUAL = 'userInfoManual';
export const MANUAL_WELCOME_SEARCH = 'overview.vehicleCharging';
export const MANUAL_WHATS_NEW_SEARCH = 'whatsNew';
export const MANUAL_DETAILS_CHARGING_SEARCH = 'details.chargingStatus';
export const MANUAL_TIMER_CHARGING_SEARCH = 'details.timerCharging';
export const MANUAL_CLIMATE_CONTROL_SEARCH = 'details.climateControl';
export const MANUAL_STATE_CHARGE_SEARCH = 'details.stateOfCharge';
export const MANUAL_VEHICLE_STATUS_SEARCH = 'details.vehicleStatus';
export const MANUAL_FLEET_EVENTS_SEARCH = 'details.fleetEvents';
export const MANUAL_DOWNLOAD_HISTORY_SEARCH = 'details.downloadHistory';
export const MANUAL_BATTERY_HEALTH_SEARCH = 'details.batteryHealth';
export const MANUAL_BATTERY_HEALTH_HISTORICAL_SEARCH = 'details.batteryHealthHistoricalView';
export const MANUAL_NEW_HISTORY_SEARCH = 'details.newHistory';

export const EVENTS_PATH = '/overview/events';
export const NEW_EVENTS_PATH = '/overview/events/new';
export const SEEN_EVENTS_PATH = '/overview/events/seen';
export const DELETE_EVENT_PATH = '/overview/events/delete';
export const EVENT_SETTINGS_PATH = '/overview/events/settings';

export const NEW_HISTORY_PATH = '/history/new';

export const BATTERY_HEALTH_DETAILS_PATH = '/batteryHealth/:assetId/details';
