import { WhatsNewDataItem } from './types';
import batteryHealthUc1 from '../../../../../assets/images/whatsNew/battery-health-uc1.gif';
import batteryHealthUc2 from '../../../../../assets/images/whatsNew/battery-health-uc2.gif';
import newHistory from '../../../../../assets/images/whatsNew/new-history.gif';
import notificationBell from '../../../../../assets/images/whatsNew/notification-bell.png';
import batterHealthDisable from '../../../../../assets/images/whatsNew/battery-health-hidden.png';
import improvedDownload from '../../../../../assets/images/whatsNew/improved-download.png';
import { FeatureToggles } from '../../../../../../../configuration/featureToggle/featureToggles';

export const whatsNewData: WhatsNewDataItem[] = [
    {
        version: 'e4c.whatsNew.version.improvedDownload',
        date: '21.08.2024',
        content: {
            title: {
                value: 'e4c.whatsNew.improvedDownload.title',
            },
            description: {
                value: 'e4c.whatsNew.improvedDownload.description',
            },
            image: {
                value: improvedDownload,
            },
        },
        toggle: FeatureToggles.REDESIGN_DOWNLOAD,
    },
    {
        version: 'e4c.whatsNew.version.changevalues',
        date: '19.06.2024',
        content: {
            title: {
                value: 'e4c.whatsNew.changevalues.title',
            },
            description: {
                value: 'e4c.whatsNew.changevalues.description',
            },
        },
    },
    {
        version: 'e4c.whatsNew.version.sohdisabled',
        date: '11.06.2024',
        content: {
            title: {
                value: 'e4c.whatsNew.sohdisabled.title',
            },
            description: {
                value: 'e4c.whatsNew.sohdisabled.description',
            },
            image: {
                value: batterHealthDisable,
            },
        },
    },
    {
        version: 'e4c.whatsNew.version.notificationBell',
        date: '08.05.2024',
        content: {
            title: {
                value: 'e4c.whatsNew.notificationBell.title',
            },
            description: {
                value: 'e4c.whatsNew.notificationBell.description',
            },
            image: {
                value: notificationBell,
            },
        },
    },
    {
        version: 'e4c.whatsNew.version.batteryHealthHistoricalView',
        date: '30.01.2024',
        content: {
            title: {
                value: 'e4c.whatsNew.batteryHealthHistoricalView.title',
            },
            description: {
                value: 'e4c.whatsNew.batteryHealthHistoricalView.description',
            },
            image: {
                value: batteryHealthUc2,
            },
            footer: {
                value: 'e4c.whatsNew.bookingTypeAvailabilityM',
            },
        },
        toggle: FeatureToggles.BATTERY_ANALYTICS_UC2,
    },
    {
        version: 'e4c.whatsNew.version.history',
        date: '28.11.2023',
        content: {
            title: {
                value: 'e4c.whatsNew.history.title',
            },
            description: {
                value: 'e4c.whatsNew.history.description',
            },
            image: {
                value: newHistory,
            },
            footer: {
                value: 'e4c.whatsNew.bookingTypeAvailabilityM',
            },
        },
        toggle: FeatureToggles.NEW_HISTORY,
    },
    {
        version: 'e4c.whatsNew.version.batteryStateOfHealth',
        date: '26.09.2023',
        content: {
            title: {
                value: 'e4c.whatsNew.batteryStateOfHealth.title',
            },
            description: {
                value: 'e4c.whatsNew.batteryStateOfHealth.description',
            },
            image: {
                value: batteryHealthUc1,
            },
            footer: {
                value: 'e4c.whatsNew.bookingTypeAvailabilityM',
            },
        },
    },
];
