import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Line from '@rio-cloud/rio-uikit/Line';
import Legend from '@rio-cloud/rio-uikit/Legend';
import ChartTooltip from '@rio-cloud/rio-uikit/ChartTooltip';
import LineChart from '@rio-cloud/rio-uikit/LineChart';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import { State } from '../../../../types';
import { getAccessToken } from '../../../../configuration';
import ErrorState from '../../common/components/states/ErrorState';
import { useGetBatteryHealthHistory } from '../queries/useGetBatteryHealth';
import { BatteryHistory } from '../types';
import { HistoricalGraphTooltip } from './HistoricalGraphTooltip';

export const getFormattedData = (data: BatteryHistory[] | undefined) => {
    if (!data) {
        return [];
    }
    const formattedData = data.map(entry => ({
        status: entry.status,
        percentage: entry.percentage,
        fullChargeCapacity: entry.fullChargeCapacity,
        dateAt: new Date(entry.dateAt).toLocaleDateString(),
    }));
    return formattedData.reverse();
};

const HistoricalGraph = () => {
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const { assetId } = useParams<{ assetId: string }>();
    const { isLoading, batteryHistory, isError } = useGetBatteryHealthHistory(accessToken, assetId);

    if (isLoading) {
        return <ContentLoader height="500" />;
    }
    if (isError) {
        return <ErrorState stateProps={{ type: 'general' }} />;
    }
    if (!batteryHistory || batteryHistory.length === 0) {
        return (
            <ErrorState
                stateProps={{
                    type: 'notfound',
                    message: <FormattedMessage id="e4c.vehicles.notFoundState.sidebar.message" />,
                }}
            />
        );
    }
    return (
        <div data-testid="soh-historical-chart" className="height-100pct width-100pct">
            <LineChart
                tooltip={<ChartTooltip content={<HistoricalGraphTooltip />} />}
                legend={
                    <Legend
                        layout="horizontal"
                        verticalAlign="bottom"
                        align="left"
                        wrapperStyle={{
                            marginLeft: 25,
                        }}
                        formatter={() => (
                            <span className="text-color-black">
                                <FormattedMessage id="e4c.batteryHealth.vehicle.sidebar.batteryAgingTitle" />
                            </span>
                        )}
                    />
                }
                margin={{ left: 0, top: 5, right: 5, bottom: 0 }}
                data={getFormattedData(batteryHistory)}
                dataKey={(entry: { dateAt: string }) => entry.dateAt}
                showYAxis
                showGrid
                dataUnit="%"
                lines={[
                    <Line
                        key="line1"
                        dataKey="percentage"
                        dot={false}
                        activeDot={{ r: 5 }}
                        legendType="square"
                        strokeColor="brand-primary"
                        type="monotone"
                    />,
                ]}
                xAxisOptions={{ interval: 'preserveStartEnd' }}
                yAxisOptions={{ domain: [50, 100] }}
            />
        </div>
    );
};

export default HistoricalGraph;
