import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { useIntl } from 'react-intl';
import { ProductVersion } from '../../common/vehicles/productVersion';
import NoData from '../common/NoData';

export interface SohNumberContentProps {
    content?: string | null;
    productVersion: ProductVersion;
}

const SohElement = ({ content, productVersion }: SohNumberContentProps) => {
    const intl = useIntl();
    const contentView = content != null ? <p className="text-size-14 padding-top-5">{content}</p> : <NoData />;
    return (
        <div>
            {productVersion === ProductVersion.EMANAGER_M ? (
                contentView
            ) : (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip width="auto" className="top">
                            <span>
                                {intl.formatMessage({
                                    id: 'e4c.batteryHealth.table.lock.tooltip',
                                })}
                            </span>
                        </Tooltip>
                    }
                >
                    <span data-testid="lock-icon" className="rioglyph rioglyph-lock text-color-gray text-size-14" />
                </OverlayTrigger>
            )}
        </div>
    );
};

export default SohElement;
