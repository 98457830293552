import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Asset } from '../../common/types';
import { State } from '../../../../types';
import { ErrorCodeEnum } from '../../common/enums/ErrorCode';

export const ASSETS_LOADING_STARTED = 'ASSETS_LOADING_STARTED';
export const ASSETS_LOADING_SUCCESSFUL = 'ASSETS_LOADING_SUCCESSFUL';
export const ASSETS_LOADING_FAILED = 'ASSETS_LOADING_FAILED';
export const ASSET_SELECTED = 'ASSET_SELECTED';
export const ASSETS_SELECTED = 'ASSETS_SELECTED';
export const GROUPS_SELECTED = 'GROUPS_SELECTED';

export interface AssetsLoadingStartedAction {
    type: typeof ASSETS_LOADING_STARTED;
}

export interface AssetsLoadingSuccessfulAction {
    type: typeof ASSETS_LOADING_SUCCESSFUL;
    payload: Asset[];
}

export interface AssetsLoadingFailedAction {
    type: typeof ASSETS_LOADING_FAILED;
    payload: ErrorCodeEnum | null;
}

export interface AssetsSelectedAction {
    type: typeof ASSETS_SELECTED;
    payload: string[];
}

export interface GroupsSelectedAction {
    type: typeof GROUPS_SELECTED;
    payload: string[];
}

export type AssetsActions =
    | AssetsLoadingStartedAction
    | AssetsLoadingSuccessfulAction
    | AssetsLoadingFailedAction
    | AssetsSelectedAction
    | GroupsSelectedAction;

export type AssetsThunkAction<R> = ThunkAction<R, State, undefined, AssetsActions>;
export type AssetsThunkDispatch = ThunkDispatch<State, undefined, AssetsActions>;
