import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import TableSearch from '@rio-cloud/rio-uikit/TableSearch';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import LoadMoreButton from '@rio-cloud/rio-uikit/LoadMoreButton';
import TableContent from './TableContent';
import TableErrorState from './TableErrorState';
import TableHeader from './TableHeader';
import TableLoader from './TableLoader';
import { SortColumn } from '../types';
import AssetBatteryHealth from '../Models/AssetBatteryHealth';
import { useFeatureToggle } from '../../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../configuration/featureToggle/featureToggles';

export const TOTAL_TABLE_ROWS = 20;

export interface TableProps {
    data: AssetBatteryHealth[];
    isLoading: boolean;
    isError: boolean;
    handleSort: Function;
    filterBySearchValue: Function;
}

const Table = ({ data, isLoading, isError, handleSort, filterBySearchValue }: TableProps) => {
    const intl = useIntl();
    const [sortBy, setSortBy] = useState(SortColumn.status);
    const [sortDir, setSortDir] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [numberOfRows, setNumberOfRows] = useState(TOTAL_TABLE_ROWS);
    const [tableContent, setTableContent] = useState([...data]);
    const { value: isEnabled } = useFeatureToggle(FeatureToggles.BATTERY_ANALYTICS_UC2);

    const hasError = isError && !isLoading;
    const hasData = Boolean(tableContent.length);
    const calculateNumberOfRows =
        numberOfRows + TOTAL_TABLE_ROWS < tableContent.length ? numberOfRows + TOTAL_TABLE_ROWS : tableContent.length;
    const showLoadMore = TOTAL_TABLE_ROWS < tableContent.length && !hasError;

    useEffect(() => {
        setTableContent(filterBySearchValue(searchValue));
    }, [searchValue, filterBySearchValue]);

    useEffect(() => {
        setTableContent(previousState => handleSort(previousState, sortBy, sortDir));
    }, [handleSort, sortDir, sortBy, searchValue]);

    const handleLoadMore = () => setNumberOfRows(calculateNumberOfRows);

    const handleSortChange = (newSortBy: SortColumn) => {
        setSortBy(newSortBy);
        setSortDir(!sortDir);
    };

    const handleSearchValueChange = (searchInput: string) => {
        setSearchValue(searchInput);
    };

    return (
        <div data-testid="table-with-search">
            <TableToolbar>
                <div className="table-toolbar-container">
                    <div className="table-toolbar-group-left">
                        <div className="table-toolbar-column">
                            <label className="table-toolbar-label">
                                {intl.formatMessage({ id: 'e4c.batteryHealth.table.searchFor' })}
                            </label>
                            <TableSearch
                                value={searchValue}
                                onChange={handleSearchValueChange}
                                placeholder={intl.formatMessage({ id: 'e4c.vehicles.search.placeholder' })}
                            />
                        </div>
                    </div>
                </div>
            </TableToolbar>
            <table
                className={`table table-layout-fixed table-column-overflow-hidden table-head-filled margin-bottom-25 ${
                    isEnabled && 'table-hover'
                }`}
            >
                <TableHeader sortBy={sortBy} sortDir={sortDir} handleSortChange={handleSortChange} />
                {isLoading ? (
                    <TableLoader />
                ) : (
                    hasData && !hasError && <TableContent data={tableContent} totalNumberRowsLoaded={numberOfRows} />
                )}
            </table>
            {!isLoading && (
                <div className="margin-bottom-25">
                    <TableErrorState hasData={hasData} error={hasError} />
                </div>
            )}
            {showLoadMore && (
                <div data-testid="load-more-button">
                    <LoadMoreButton
                        loaded={numberOfRows}
                        total={data.length}
                        onLoadMore={handleLoadMore}
                        loadMoreMessage={intl.formatMessage({ id: 'e4c.overview.events.widget.modal.loadMore' })}
                        noMoreMessage={intl.formatMessage({
                            id: 'e4c.batteryHealth.table.loadMore.everythingIsLoaded',
                        })}
                        isInteractive={true}
                    />
                </div>
            )}
        </div>
    );
};

export default Table;
