import { TreeGroup } from '@rio-cloud/rio-uikit/Tree';
import { State } from '../../../../types';
import { ErrorCodeEnum } from '../../common/enums/ErrorCode';

export const getTags = (state: State): TreeGroup[] => {
    return state.tags.tags;
};

export const getFetchingTags = (state: State): boolean => {
    return state.tags.fetching;
};

export const getTagsErrorCode = (state: State): ErrorCodeEnum | null => {
    return state.tags.errorCode;
};
