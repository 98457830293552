import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { default as RioSidebar } from '@rio-cloud/rio-uikit/Sidebar';
import { State } from '../../../../types';
import { getAccessToken } from '../../../../configuration';
import { useGetBatteryHealthSidebar } from '../queries/useGetBatteryHealth';
import { BATTERY_HEALTH_PATH } from '../../../../configuration/setup/path';
import LabelledText from '../../common/components/labelledText/LabelledText';
import VehicleIdentification from '../../common/components/Tables/VehicleIdentification/VehicleIdentification';
import VehicleType from '../../common/vehicles/VehicleType';
import HistoricalGraph from './HistoricalGraph';

export const Sidebar = () => {
    const history = useHistory();
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const { isLoading, batteryHealthData } = useGetBatteryHealthSidebar(accessToken);
    const { assetId: paramsAssetId } = useParams<{ assetId: string }>();

    const headerData = batteryHealthData.assetBatteryHealthList.find(data => data.assetId === paramsAssetId);
    const { name, productVersion, vin } = headerData || {};

    const contentLoader = <ContentLoader width="15%" />;

    return (
        <ApplicationLayout.Sidebar className="right">
            <RioSidebar
                fly
                title={<FormattedMessage id="e4c.batteryHealth.vehicle.sidebar.header" />}
                titleClassName="padding-left-5"
                headerClassName="min-height-0"
                onClose={() => {
                    history.push(BATTERY_HEALTH_PATH);
                }}
                data-testid="battery-health-sidebar"
                width={615}
            >
                <div className="margin-x-20 margin-bottom-20">
                    {!isLoading && name && productVersion ? (
                        <VehicleIdentification
                            name={name}
                            productVersion={productVersion}
                            vehicleType={VehicleType.BUS}
                        />
                    ) : (
                        contentLoader
                    )}
                    <div className="display-flex align-items-center margin-top-10 margin-left-5">
                        <LabelledText label="VIN" text={vin} isLoading={isLoading} contentLoaderWidth="15%" />
                    </div>
                </div>
                <hr className="margin-0" />
                <div className="margin-top-10 margin-x-25">
                    <p className="text-size-16 text-medium">
                        <FormattedMessage id="e4c.batteryHealth.vehicle.sidebar.batteryAgingTitle" />
                    </p>
                    <FormattedMessage id="e4c.batteryHealth.vehicle.sidebar.batteryAgingDescription" />
                </div>
                <div className="margin-25 height-500">
                    <HistoricalGraph />
                </div>
            </RioSidebar>
        </ApplicationLayout.Sidebar>
    );
};
