import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CalloutUpsell from './CalloutUpsell';
import { getCookie, setCookie } from '../../common/utilities/cookies';
import { getUserAccount } from '../../../../configuration';
import { config } from '../../../../config';
import { ProductSKU } from '../../common/components/upsell/UpSellButton';

interface HistoryUpsellProps {
    hasMBooking: boolean;
    hasMinimalDataset: boolean;
}

const getProductSKU = (hasMBooking: boolean, hasMinimalDataset: boolean) => {
    if (hasMBooking && !hasMinimalDataset) {
        return ProductSKU.minimalDataSet;
    }
    return ProductSKU.eManagerM;
};

const HistoryUpsell = ({ hasMBooking, hasMinimalDataset }: HistoryUpsellProps): ReactElement => {
    const hasProducts = hasMBooking && hasMinimalDataset;
    const [show, setShow] = useState(!hasProducts);

    const userAccount = useSelector(state => getUserAccount(state));

    useEffect(() => {
        if (!hasProducts) {
            const cookie = getCookie(`calloutupsell-${userAccount}`);
            setShow(!cookie);
        }
    }, [hasProducts, userAccount]);

    const handleClose = () => {
        setCookie({ key: `calloutupsell-${userAccount}`, value: 'true', expireDate: 40 });
        setShow(false);
    };

    return (
        <>
            {show && (
                <CalloutUpsell
                    subtitle={
                        hasMBooking
                            ? 'e4c.history.upsellBanner.descriptionMinimalDatenSet'
                            : 'e4c.history.upsellBanner.descriptionM'
                    }
                    title={
                        hasMBooking
                            ? 'e4c.history.upsellBanner.titleMinimalDatenset'
                            : 'e4c.history.upsellBanner.titleM'
                    }
                    infoUrl={hasMBooking ? config.minimalDatasetLearnMoreUrl : config.eManagerMLearnMoreUrl}
                    productSKU={getProductSKU(hasMBooking, hasMinimalDataset)}
                    handleClose={handleClose}
                />
            )}
        </>
    );
};

export default HistoryUpsell;
