import { TagsState } from './types';
import {
    TagsActions,
    TAGS_LOADING_STARTED,
    TAGS_LOADING_SUCCESSFUL,
    TAGS_LOADING_FAILED,
} from '../../actions/tags/types';

const initialState: TagsState = {
    tags: [],
    fetching: false,
    errorCode: null,
};

const reducer = (state: TagsState = initialState, action: TagsActions): TagsState => {
    switch (action.type) {
        case TAGS_LOADING_STARTED:
            return {
                ...state,
                fetching: true,
                tags: [],
                errorCode: null,
            };
        case TAGS_LOADING_SUCCESSFUL:
            return {
                ...state,
                fetching: false,
                tags: action.payload,
                errorCode: null,
            };
        case TAGS_LOADING_FAILED:
            return {
                ...state,
                fetching: false,
                tags: [],
                errorCode: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
