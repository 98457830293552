import { State } from '../../../types';
import { HistoryContentEnum } from './types';
import { SortDirection } from '../vehicleCharging/types';
import ChargingProcess from './models/ChargingProcess';
import { ErrorCodeEnum } from '../common/enums/ErrorCode';
import { ProductVersion } from '../common/vehicles/productVersion';

export const getChargingProcesses = (state: State): ChargingProcess[] => {
    return state.vehiclesHistory.chargingProcesses;
};

export const getFetchingChargingProcesses = (state: State): boolean => {
    return state.vehiclesHistory.fetchingChargingProcesses;
};

export const getChargingProcessesErrorCode = (state: State): ErrorCodeEnum | null => {
    return state.vehiclesHistory.chargingProcessesErrorCode;
};

export const getSelectedHistoryAssetIds = (state: State): string[] => {
    return state.vehiclesHistory.selectedHistoryAssetIds;
};

export const getHistorySortBy = (state: State): string => {
    return state.vehiclesHistory.historySortBy;
};

export const getHistorySortDir = (state: State): SortDirection => {
    return state.vehiclesHistory.historySortDir;
};

export const getSelectedHistoryContent = (state: State): HistoryContentEnum => {
    return state.vehiclesHistory.selectedHistoryContent;
};

export const hasMinimalDatenset = (state: State): boolean => {
    return state.electricVehicles.hasMinimalDatenset;
};

export const getFetchingForHistoryTab = (state: State): boolean => {
    return (
        state.electricVehicles.isFetchingMonitoringElectricVehicles ||
        state.electricVehicles.isFetchingStateElectricVehicles ||
        state.tags.fetching ||
        state.assets.fetching ||
        state.vehiclesHistory.fetchingChargingProcesses
    );
};

export const hasMBooking = (state: State): boolean => {
    return state.electricVehicles.electricVehicles.some(e => e.productVersion === ProductVersion.EMANAGER_M);
};
