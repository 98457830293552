import { BatteryStatusInfoResponse, StatusType } from '../types';

class BatteryStatusInfo {
    public status: StatusType;
    public percentage: number | null;
    public outdated: boolean;
    public lastUpdatedAt: Date;
    public fullChargeCapacity: number | null;
    constructor(batteryStatus: BatteryStatusInfoResponse) {
        this.status = batteryStatus.status as StatusType;
        this.percentage = batteryStatus.percentage;
        this.outdated = batteryStatus.outdated;
        this.lastUpdatedAt = new Date(batteryStatus.last_updated_at);
        this.fullChargeCapacity = batteryStatus.full_charge_capacity;
    }
}

export default BatteryStatusInfo;
