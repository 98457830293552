/* eslint-disable react-hooks/exhaustive-deps */
// we should discuss the relevance of this warning
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import moment, { Moment } from 'moment';
import { TreeGroup } from '@rio-cloud/rio-uikit/Tree';
import AssetTreeSidebar from '../../common/components/AssetTreeSidebar/AssetTreeSidebar';
import { HistoryToolbar } from '../components/tableToolbar/HistoryToolbar';
import { HistoryContent } from '../components/historyTable/HistoryTableContent';
import { FAKE_ASSETS, TEAM_ACCOUNT } from '../components/fakeAssets';
import { HistoryContentEnum } from '../components/types';
import { handleSelectedTab, handleStartAndEndDate, updateUrl, updateUrlWithTab } from './helper/HistoryComponentHelper';
import ChargingProcessList from '../models/ChargingProcessList';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../utils/googleTagManager';
import ErrorState from '../../common/components/states/ErrorState';
import ApplicationLayoutSkeleton from '../../common/components/applicatonLayout';
import UpsellPanel from '../components/UpsellPanel';
import { Asset } from '../../common/types';
import { ErrorCodeEnum } from '../../common/enums/ErrorCode';
import ChargingProcess from '../models/ChargingProcess';
import ElectricVehicle from '../../vehicleCharging/models/ElectricVehicle';
import { SortDirection } from '../../vehicleCharging/types';
import { useFullFeatureToggleByUsername } from '../../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../configuration/featureToggle/featureToggles';

interface HistoryComponentProps {
    accountId: string | null;
    assets: Asset[];
    assetsErrorCode: ErrorCodeEnum | null;
    chargingProcesses: ChargingProcess[];
    chargingProcessesErrorCode: ErrorCodeEnum | null;
    defaultEndValueDate?: Moment | undefined;
    defaultStartValueDate?: Moment | undefined;
    electricVehicles: ElectricVehicle[];
    isFetchingForHistoryTab: boolean;
    fetchVehicleChargingProcesses: (startDate?: Moment, endDate?: Moment) => void;
    handleHistoryTableSortChange: (sortDir: SortDirection, sortBy: string) => void;
    hasMinimalDatenset: boolean;
    historySortBy: string;
    historySortDir: SortDirection;
    selectAssets: (assetIds: string[]) => void;
    selectedAssetIds: string[];
    selectGroups: (groupIds: string[]) => void;
    selectedGroupIds: string[];
    selectedHistoryContent: HistoryContentEnum;
    tags: TreeGroup[];
    tagsErrorCode: ErrorCodeEnum | null;
    hasMBooking: boolean;
}

const HistoryComponent = ({
    accountId,
    assets,
    assetsErrorCode,
    chargingProcesses,
    chargingProcessesErrorCode,
    defaultEndValueDate,
    defaultStartValueDate,
    electricVehicles,
    isFetchingForHistoryTab,
    fetchVehicleChargingProcesses,
    handleHistoryTableSortChange,
    hasMinimalDatenset,
    historySortBy,
    historySortDir,
    selectAssets,
    selectedAssetIds,
    selectGroups,
    selectedGroupIds,
    selectedHistoryContent,
    tags,
    tagsErrorCode,
    hasMBooking,
}: HistoryComponentProps): ReactElement => {
    const history = useHistory();
    const [defaultStartValue, setDefaultStartValue] = useState<Moment | undefined>(defaultStartValueDate);
    const [defaultEndValue, setDefaultEndValue] = useState<Moment | undefined>(defaultEndValueDate);
    const [selectedContent, setSelectedContent] = useState<HistoryContentEnum>(selectedHistoryContent);
    const { variationId } = useFullFeatureToggleByUsername(FeatureToggles.NEW_HISTORY_PRE_SELECTED);

    useEffect(() => {
        if (variationId !== undefined) {
            gtmUtil.pushGaEvent(
                createGoogleAnalyticsEvent(TrackingCategory.HISTORY_TAB, TrackingAction.VISIT_OLD_HISTORY, variationId)
            );
        }
    }, [variationId]);

    useEffect(() => {
        handleStartAndEndDate(
            history,
            (startDate: Moment, endDate: Moment) => {
                setDefaultEndValue(endDate);
                setDefaultStartValue(startDate);
                fetchVehicleChargingProcesses(startDate, endDate);
            },
            () => {
                fetchVehicleChargingProcesses();
            }
        );
        handleSelectedTab(
            history,
            (tab: HistoryContentEnum) => {
                setSelectedContent(tab);
            },
            () => {
                updateUrlWithTab(history, selectedHistoryContent);
            }
        );
    }, []);

    useEffect(() => {
        updateUrlWithTab(history, selectedContent);
    }, [selectedContent]);

    const manageDateChange = useCallback(
        (start: Moment, end: Moment) => {
            updateUrl(history, start, end);
            setDefaultEndValue(end);
            setDefaultStartValue(start);
        },
        [history]
    );

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);
        if (!searchParams.has('startDate') && !searchParams.has('endDate')) {
            manageDateChange(moment().subtract(4, 'days').startOf('day'), moment().endOf('day'));
        }
    }, [history.location.search, manageDateChange]);

    const assetsResult = useMemo(() => {
        // This method is only necessary to mock data on production
        // It should be removed if we get development environment
        return accountId !== TEAM_ACCOUNT ? assets : assets.concat(FAKE_ASSETS);
    }, [accountId, assets]);

    const onChangeSelectedRangeDate = (startValue: any, endValue: any): void => {
        setDefaultEndValue(endValue);
        setDefaultStartValue(startValue);
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(
                TrackingCategory.HISTORY_TAB,
                TrackingAction.CHANGE_DATES,
                `${startValue} - ${endValue}`
            )
        );
        updateUrl(history, startValue, endValue);
        fetchVehicleChargingProcesses(startValue, endValue);
    };

    const isSidebarError = assetsErrorCode || tagsErrorCode;
    const isBodyError = chargingProcessesErrorCode;
    const hasNoPaidProducts = !hasMBooking && !hasMinimalDatenset;
    const bodyWithMBooking = (
        <>
            <HistoryToolbar
                changeSelectedRangeDate={onChangeSelectedRangeDate}
                handleSelectedContentChange={setSelectedContent}
                hasMinimalDatenset={hasMinimalDatenset}
                hasMBooking={hasMBooking}
                defaultStartValue={defaultStartValue}
                selectedContent={selectedContent}
                defaultEndValue={defaultEndValue}
            />
            {!isBodyError ? (
                <HistoryContent
                    assets={assetsResult}
                    historySortBy={historySortBy}
                    historySortDir={historySortDir}
                    chargingProcesses={new ChargingProcessList(chargingProcesses).filterByAssetId(selectedAssetIds)}
                    handleHistoryTableSortChange={handleHistoryTableSortChange}
                    selectedAssetIds={selectedAssetIds}
                    selectedHistoryContent={selectedContent}
                />
            ) : (
                <ErrorState stateProps={{ type: 'general' }} verticalAlign={'80'} />
            )}
        </>
    );

    return (
        <ApplicationLayoutSkeleton
            isLoading={isFetchingForHistoryTab}
            sidebarLeftContent={{
                content: !isSidebarError && (
                    <AssetTreeSidebar
                        assets={assetsResult}
                        defaultEndValue={defaultEndValue}
                        electricVehicles={electricVehicles}
                        hasMultiselect={true}
                        onSelectAssets={selectAssets}
                        onSelectGroups={selectGroups}
                        selectedAssetIds={selectedAssetIds}
                        selectedGroupIds={selectedGroupIds}
                        setDefaultEndValue={setDefaultEndValue}
                        setDefaultStartValue={setDefaultStartValue}
                        tags={tags}
                    />
                ),
            }}
        >
            {hasNoPaidProducts ? <UpsellPanel /> : bodyWithMBooking}
        </ApplicationLayoutSkeleton>
    );
};

export default HistoryComponent;
