export const FeatureToggles = {
    NEW_HISTORY: 'newHistory',
    NEW_HISTORY_PRE_SELECTED: 'newHistoryPreSelectedV2',
    BATTERY_ANALYTICS_UC2: 'batteryAnalyticsUC2',
    HIDE_SOH_VALUES: 'hideSohValues',
    BATTERY_HEALTH: 'batteryHealth',
    EARLY_FLEET: 'earlyFleet',
    REDESIGN_DOWNLOAD: 'redesignDownload',
    SHOW_OVERVIEW_CHARTS: 'showOverviewCharts',
} as const;
