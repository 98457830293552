import { State } from '../../../../types';
import { ErrorCodeEnum } from '../../common/enums/ErrorCode';
import { Asset } from '../../common/types';

export const getFetching = (state: State): boolean => {
    return state.assets.fetching;
};

export const getAssets = (state: State): Asset[] => {
    return state.assets.assets;
};

export const getSelectedAssetIds = (state: State): string[] => {
    return state.assets.selectedAssetIds;
};

export const getSelectedGroupIds = (state: State): string[] => {
    return state.assets.selectedGroupIds;
};

export const getAssetsErrorCode = (state: State): ErrorCodeEnum | null => {
    return state.assets.errorCode;
};
