import { useQuery, UseQueryOptions } from 'react-query';
import { AccessToken } from '../../../../configuration';
import AssetBatteryHealthList from '../Models/AssetBatteryHealthList';
import { fetchBatteryHealth, fetchBatteryHistory, fetchBatteryStatistics } from '../../../../api/batteryHealth';
import BatteryStatistics from '../Models/BatteryStatistics';
import { convertObjectKeysToCamelCase } from '../../common/helpers/objectConversionHelper';
import { BatteryHistory, BatteryHistoryResponse } from '../types';

const useBatteryHealthInternal = (accessToken: AccessToken, options?: UseQueryOptions<any, any, any, string[]>) => {
    const { isLoading, isError, isSuccess, data } = useQuery(
        ['battery-health'],
        () => fetchBatteryHealth({ accessToken }).then(res => res.json()),
        options
    );
    const batteryHealthData = new AssetBatteryHealthList(data ? data.battery_status : []);

    return { isLoading, isError, isSuccess, batteryHealthData };
};

export const useGetBatteryHealthSidebar = (accessToken: AccessToken) => {
    return useBatteryHealthInternal(accessToken, {
        staleTime: 180000,
    });
};

export const useGetBatteryHealth = (accessToken: AccessToken) => {
    return useBatteryHealthInternal(accessToken);
};

export const useGetBatteryStatistics = (accessToken: AccessToken) => {
    const { isLoading, isError, isSuccess, data } = useQuery(['battery-health-statistic'], () =>
        fetchBatteryStatistics({ accessToken }).then(res => res.json())
    );

    const batteryStatistics = data && new BatteryStatistics(data);

    return { isLoading, isError, isSuccess, batteryStatistics };
};

export const useGetBatteryHealthHistory = (accessToken: AccessToken, assetId: string) => {
    const { isLoading, isError, isSuccess, data } = useQuery(
        ['battery-health-history', assetId],
        () => fetchBatteryHistory({ accessToken, assetId }).then(res => res.json()),
        { refetchOnWindowFocus: false }
    );

    const batteryHistory = convertObjectKeysToCamelCase<BatteryHistoryResponse[], BatteryHistory[]>(data?.history);

    return { isLoading, isError, isSuccess, batteryHistory };
};
