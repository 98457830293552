import { SortDirection } from '../../vehicleCharging/types';
import { PaginationOptions } from '../../common/components/pagination/Pagination';
import ChargingProcess from '../models/ChargingProcess';
import ChargingProcessList from '../models/ChargingProcessList';
import { Asset } from '../../common/types';
import { VerticalAlign } from '../../components/shared/Wrapper';

export enum HistoryContentEnum {
    BATTERY_HISTORY = 'BATTERY_HISTORY',
    CONSUMPTION_HISTORY = 'CONSUMPTION_HISTORY',
}

export interface HistoryContentProps {
    assets: Asset[];
    selectedAssetIds: string[];
    chargingProcesses: ChargingProcessList;
    selectedHistoryContent: HistoryContentEnum;
    historySortBy: string;
    historySortDir: SortDirection;
    handleHistoryTableSortChange: (sortDir: SortDirection, sortBy: string) => void;
}

export interface ColumnLabels {
    date: string;
    vehicle: string;
    status: string;
    remainingCapacity: string;
    stateOfCharge: string;
    duration: string;
    energyConsumed: string;
    energyAdded: string;
    current: string;
    chevron: string;
    [key: string]: string;
}

export interface HistoryTableColumnDetails {
    label?: string;
    sticky?: boolean;
    sortable: boolean;
    width?: number; // not all values are supported, see https://uikit.developers.rio.cloud/#utilityClasses#sizing-width
    tooltip?: string;
    hasRightSeparator?: boolean;
    tooltipId?: string;
}

export type BatteryHistoryTableProps = HistoryTableProps;
export type ConsumptionHistoryTableProps = HistoryTableProps;

export interface HistoryTableHeaderItemProps {
    column: string | keyof ColumnLabels;
    label: string | undefined;
    tooltip: string | undefined;
    width: string | number | undefined;
}

export interface SortableHistoryTableHeaderItemProps {
    column: string | keyof ColumnLabels;
    handleSortChange: (e: React.MouseEvent) => void;
    label: string | undefined;
    sortBy: string;
    sortDir: string;
    tooltip: string | undefined;
    width: string | number | undefined;
}

export interface HistoryTableProps {
    assets: Asset[];
    rows: ChargingProcessList;
    sortBy: string;
    sortDir: SortDirection;
    handleTableSortChange: (sortDir: SortDirection, sortBy: string) => void;
    columnsDetails: { [key: string]: HistoryTableColumnDetails };
    renderColumnValue: (column: string, chargingProcess: ChargingProcess, locale: string) => JSX.Element;
    renderRows: (
        tableRows: ChargingProcessList,
        tableColumns: string[],
        keysOpened: string[],
        locale: string,
        tableColumnsWithRightSeparator: string[],
        renderColumnValue: (column: string, chargingProcess: ChargingProcess, locale: string) => JSX.Element,
        setKeysOpened: any,
        pagination: PaginationOptions
    ) => JSX.Element[];
}

export interface ChargeCycleActivity {
    type: ChargeCycleActivityType;
    assetId: string;
    assetName: string;
    startDate: string;
    startDateTime: string;
    endTime: string | null;
    duration: number | null;
    remainingRangeKmAtStartTime: number | null;
    stateOfCharge: number | null;
    energyLevelKwhAtStartTime: number | null;
    totalEnergyConsumptionKwhAtStartTime: number | null;
    totalEnergyConsumptionKwhAtEndTime: number | null;
    averageBatteryCurrent: number | null;
    maxBatteryCurrent: number | null;
    energyAddedKwh: number | null;
    changeStartDateToLocalTimeZone: (selectedAssetIds: string[]) => void;
    powertrainEnergyConsumptionKwh: number | null;
    powertrainEnergyConsumptionPercentage: number | null;
    auxEnergyConsumptionKwh: number | null;
    auxEnergyConsumptionPercentage: number | null;
    amsEnergyConsumptionKwh: number | null;
    amsEnergyConsumptionPercentage: number | null;
    batteryVoltages: {
        averageBatteryVoltage: number;
        minBatteryVoltage: number;
        maxBatteryVoltage: number;
    } | null;
    temperatures: {
        averageTemperature: number;
        minTemperature: number;
        maxTemperature: number;
    } | null;
    maxBatteryTemperatureWarningC: number | null;
    minBatteryTemperatureWarningC: number | null;
    mileage: number | null;
}

export interface CollapsedItemsProps {
    collapsedType: string;
    rowType: string;
}

export enum ChargeCycleActivityType {
    CHARGING = 'CHARGING',
    DISCHARGING = 'DISCHARGING',
}

export interface DateColProps {
    date: string;
}

export interface DateTimeColProps {
    date: string;
}

export interface StatusProps {
    processType: ChargeCycleActivityType;
}

export interface DurationProps {
    duration: number | null;
}

export const NO_DATA_INFO = 'NO DATA';

export interface NothingSelectedStateProps {
    verticalAlign: VerticalAlign;
}
