import { useIntl } from 'react-intl';
import Popover from '@rio-cloud/rio-uikit/Popover';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import { BatteryStatus } from '../types';
import { ProductVersion } from '../../common/vehicles/productVersion';
import NoData from '../common/NoData';
import { SohStatus } from '../common/SohStatus';

const StatusToolTip = ({
    status,
    productVersion,
    percentage,
}: {
    status: BatteryStatus;
    productVersion: ProductVersion;
    percentage: string;
}) => {
    const intl = useIntl();
    const statusToolTipLabel: { [key in ProductVersion]?: string } = {};
    if (status === BatteryStatus.CRITICAL) {
        statusToolTipLabel[ProductVersion.EMANAGER_M] =
            'e4c.batteryHealth.table.batteryHealthStatusCritical.tooltip.forM';
        statusToolTipLabel[ProductVersion.EMANAGER_S] =
            'e4c.batteryHealth.table.batteryHealthStatusCritical.tooltip.forS';
    } else {
        statusToolTipLabel[ProductVersion.EMANAGER_M] =
            'e4c.batteryHealth.table.batteryHealthStatusWarning.tooltip.forM';
        statusToolTipLabel[ProductVersion.EMANAGER_S] =
            'e4c.batteryHealth.table.batteryHealthStatusWarning.tooltip.forS';
    }

    const formattedMessageValues = {
        batteryHealth: percentage,
        br: <br />,
        b: (chunks: any) => <b>{chunks}</b>,
        ul: (chunks: any) => <ul>{chunks}</ul>,
        li: (chunks: any) => <li>{chunks}</li>,
    };

    return (
        <OverlayTrigger
            placement="right"
            overlay={
                <Popover
                    id="popover-soh-satus"
                    placement="right"
                    className="margin-left-5"
                    title={intl.formatMessage({ id: 'e4c.batteryHealth.table.batteryHealthStatus.tooltip.title' })}
                >
                    {intl.formatMessage({ id: statusToolTipLabel[productVersion] }, formattedMessageValues)}
                </Popover>
            }
        >
            <span className="rioglyph rioglyph-exclamation-sign text-color-gray text-size-14 margin-left-5" />
        </OverlayTrigger>
    );
};

export interface SohStatusProps {
    status?: BatteryStatus;
    productVersion: ProductVersion;
    percentage?: number | null;
}

const TableSohStatus = ({ status, productVersion, percentage }: SohStatusProps) => {
    if (!status || !Object.values(BatteryStatus).includes(status)) {
        return <NoData />;
    }

    const showToolTip = [BatteryStatus.CRITICAL, BatteryStatus.WARNING].includes(status);

    return (
        <div>
            <SohStatus status={status} />
            {showToolTip && (
                <StatusToolTip status={status} productVersion={productVersion} percentage={`${percentage}%`} />
            )}
        </div>
    );
};

export default TableSohStatus;
