import { FormattedMessage } from 'react-intl';
import RioPieChart from '@rio-cloud/rio-uikit/PieChart';
import ChartTooltip from '@rio-cloud/rio-uikit/ChartTooltip';
import ChartLoader from './ChartLoader';
import BatteryStatistic from '../Models/BatteryStatistics';
import ErrorState from '../../common/components/states/ErrorState';
import { mapChartData } from './utils';

export const customTooltip = ({ active, payload }: { active?: boolean; payload?: any }) => {
    if (active && payload && payload.length) {
        const { totalVehicles, tooltip } = payload[0].payload;
        const tooltipSuffix = totalVehicles === 1 ? 'single' : 'plural';
        const tooltipId = `${tooltip}.${tooltipSuffix}`;
        const formattedMessageValues = {
            b: (chunks: any) => <b>{chunks}</b>,
            totalVehicles,
        };

        return (
            <div className="custom-tooltip bg-white border padding-10 min-width-100" data-testid="custom-tooltip">
                <FormattedMessage id={tooltipId} values={formattedMessageValues} />
            </div>
        );
    }
    return <></>;
};

interface PieChartProps {
    isLoading: boolean;
    data: BatteryStatistic | null;
    error: boolean;
}

const PieChart = ({ isLoading, data, error }: PieChartProps) => {
    if (isLoading) {
        return <ChartLoader />;
    }

    if (error || !data) {
        return <ErrorState stateProps={{ type: 'general', withoutBorder: true }} />;
    }

    return (
        <>
            {
                <div data-testid="pie-chart" className="height-300">
                    <RioPieChart
                        data={mapChartData(data)}
                        dataKey={(entry: any) => entry.totalVehicles}
                        outerRadius={120}
                        innerRadius={70}
                        height={300}
                        width={100}
                        nameKey="name"
                        tooltip={<ChartTooltip content={customTooltip} />}
                    />
                </div>
            }
        </>
    );
};

export default PieChart;
