import { ReactElement, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import IframeResizer from 'iframe-resizer-react';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { config } from '../../../../../config';
import { ASSETS_PATH, BATTERY_HEALTH_PATH, HISTORY_PATH, OVERVIEW_PATH } from '../../../../../configuration/setup/path';
import { ServiceInfoIconButton } from './ServiceInfoIconButton';
import FeedbackButton from './FeedbackButton';
import TooltipRef from '../Tooltip/Tooltip';
import { useFeatureToggle } from '../../hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../../configuration/featureToggle/featureToggles';
import { NEW_HISTORY_PATH } from '../../constants/paths/paths';

const homeRoute = config.homeRoute;
const appTitle = config.title;
const menuUrl = config.backend.MENU_SERVICE as string;

const UserMenu = () => {
    const intl = useIntl();
    const ref = useRef(null);
    return (
        <ActionBarItem id="menu" className="myMenu">
            <ActionBarItem.Icon className="myMenu">
                <TooltipRef
                    ref={ref}
                    dataTestId="tooltip-service-info"
                    tooltipContent={intl.formatMessage({ id: 'e4c.global.my.profile' })}
                    placement={Tooltip.BOTTOM}
                >
                    <div>
                        <DefaultUserMenu environment={import.meta.env.MODE} />
                    </div>
                </TooltipRef>
            </ActionBarItem.Icon>
        </ActionBarItem>
    );
};

const HomeLink = <a dangerouslySetInnerHTML={{ __html: '' }} href={homeRoute}></a>;
const AppNavigator = <IframeResizer src={menuUrl} />;

const Header = (): ReactElement => {
    const { value: isBatteryHealthEnabled } = useFeatureToggle(FeatureToggles.BATTERY_HEALTH);
    const { value: isEarlyFleet } = useFeatureToggle(FeatureToggles.EARLY_FLEET);

    const navItems = [
        {
            key: 'overview',
            route: (
                <NavLink to={OVERVIEW_PATH}>
                    <FormattedMessage id="e4c.overview.title" />
                </NavLink>
            ),
        },
        {
            key: 'vehicles',
            route: (
                <NavLink to={ASSETS_PATH}>
                    <FormattedMessage id="e4c.sublink.vehicles" />
                </NavLink>
            ),
        },
        ...(isBatteryHealthEnabled
            ? [
                  {
                      key: 'battery-health',
                      route: (
                          <NavLink to={BATTERY_HEALTH_PATH}>
                              <FormattedMessage id="e4c.batteryHealth.title" />
                          </NavLink>
                      ),
                  },
              ]
            : []),
        {
            key: 'history',
            route: (
                <NavLink to={isEarlyFleet ? NEW_HISTORY_PATH : HISTORY_PATH}>
                    <FormattedMessage id="e4c.history.title" />
                </NavLink>
            ),
        },
    ];

    return (
        <ApplicationLayout.Header>
            <ApplicationHeader
                label={appTitle}
                appNavigator={AppNavigator}
                homeRoute={HomeLink}
                navItems={navItems}
                actionBarItems={[
                    <FeedbackButton key="feedback" />,
                    <ServiceInfoIconButton key="serviceInfo" hasNewEntry={false} />,
                    <RioNotifications key="notifications" />,
                    <UserMenu key="userMenu" />,
                ]}
            />
        </ApplicationLayout.Header>
    );
};

export default Header;
