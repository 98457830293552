import { useIntl } from 'react-intl';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import { SortColumn } from '../types';
import SortDirection from '../../common/utilities/sort/SortDirection';

interface TableHeaderProps {
    handleSortChange: (column: SortColumn) => void;
    sortBy: SortColumn;
    sortDir: boolean;
}

const TableHeader = ({ handleSortChange, sortBy = SortColumn.status, sortDir = true }: TableHeaderProps) => {
    const intl = useIntl();
    const direction = sortDir ? SortDirection.ASCENDING : SortDirection.DESCENDING;
    return (
        <thead data-testid="table-header" className="text-color-black">
            <tr>
                <th>{intl.formatMessage({ id: 'e4c.vehicles.vehicle' })}</th>
                <th
                    className="user-select-none, sort-column"
                    onClick={() => handleSortChange(SortColumn.status)}
                    role="table-header-status"
                    data-field="status"
                    data-sortby="status"
                    title={intl.formatMessage({ id: 'e4c.batteryHealth.tableHeader.stateOfHealthStatus' })}
                >
                    <span>
                        {sortBy === SortColumn.status ? <SortArrows direction={direction} /> : <SortArrows />}
                        <span>{intl.formatMessage({ id: 'e4c.batteryHealth.tableHeader.stateOfHealthStatus' })}</span>
                    </span>
                </th>
                <th
                    className="user-select-none, sort-column"
                    onClick={() => handleSortChange(SortColumn.percentage)}
                    role="table-header-percentage"
                    data-field="percentage"
                    data-sortby="percentage"
                    title={intl.formatMessage({ id: 'e4c.batteryHealth.tableHeader.stateOfHealthPercentage' })}
                >
                    <span>
                        {sortBy === SortColumn.percentage ? <SortArrows direction={direction} /> : <SortArrows />}
                        <span>
                            {intl.formatMessage({ id: 'e4c.batteryHealth.tableHeader.stateOfHealthPercentage' })}
                        </span>
                    </span>
                </th>
                <th
                    className="user-select-none, sort-column"
                    onClick={() => handleSortChange(SortColumn.fullChargeCapacity)}
                    role="table-header-fullChargeCapacity"
                    data-field="fullChargeCapacity"
                    data-sortby="fullChargeCapacity"
                    title={intl.formatMessage({ id: 'e4c.batteryHealth.tableHeader.fullChargeCapacity' })}
                >
                    <span>
                        {sortBy === SortColumn.fullChargeCapacity ? (
                            <SortArrows direction={direction} />
                        ) : (
                            <SortArrows />
                        )}
                        <span>
                            {intl.formatMessage({
                                id: 'e4c.batteryHealth.tableHeader.fullChargeCapacity',
                            })}
                        </span>
                    </span>
                </th>
            </tr>
        </thead>
    );
};

export default TableHeader;
