import { AccessToken } from '../configuration';
import { isDevEnvironment } from './utils';
import { config } from '../config';
import { getLocalAccessToken } from './Api';

const BATTERY_SERVICE = config.backend.BATTERY_SERVICE;

interface BatteryHealthArgs {
    accessToken: AccessToken;
    contentType?: string;
    body?: string;
}

interface BatteryStatisticArgs extends BatteryHealthArgs {
    type?: string;
}

interface BatteryHistoryArgs extends BatteryHealthArgs {
    assetId: string;
}

const batteryServiceEndpoint = () => `${BATTERY_SERVICE}/assets`;

const generateHeaders = async (accessToken: AccessToken, contentType = 'application/json') => {
    const bearerToken = isDevEnvironment()
        ? await getLocalAccessToken('batteryservicetoken', 'http://localhost:9004')
        : accessToken;
    return {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': contentType,
    };
};

const makeRequest = async (url: string, args: BatteryHealthArgs): Promise<Response> => {
    const { accessToken, contentType, body } = args;
    const headers = await generateHeaders(accessToken, contentType);

    const response = await fetch(url, {
        headers,
        body,
        method: 'GET',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response;
};

export const fetchBatteryHealth = async ({ accessToken, contentType, body }: BatteryHealthArgs): Promise<Response> => {
    const batteryHealthEndpoint = `${batteryServiceEndpoint()}/batteries/health`;
    return makeRequest(batteryHealthEndpoint, { accessToken, contentType, body });
};

export const fetchBatteryStatistics = async ({
    accessToken,
    contentType,
    body,
    type = 'STATUS',
}: BatteryStatisticArgs): Promise<Response> => {
    const batteryHealthEndpoint = `${batteryServiceEndpoint()}/batteries/statistics?statistic_type=${type}`;
    return makeRequest(batteryHealthEndpoint, { accessToken, contentType, body });
};

export const fetchBatteryHistory = async ({
    accessToken,
    contentType,
    body,
    assetId,
}: BatteryHistoryArgs): Promise<Response> => {
    const batteryHealthEndpoint = `${batteryServiceEndpoint()}/${assetId}/batteries/health/history`;
    return makeRequest(batteryHealthEndpoint, { accessToken, contentType, body });
};
