import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import UserManualPage from '../components/UserManualPage';
import UserManualSection from '../components/UserManualSection';
import { useLocation } from 'react-router';
import { addQueryParam, removeQueryParam } from '../../../../helpers/queryParametersHelper';
import { USER_MANUAL } from '../../../../constants/paths/paths';
import { Link } from 'react-router-dom';
import detailBatteryHealthHistoricalImage from '../../../../../assets/images/detail-battery-health-historical.png';

const BatteryHealthHistoricalItem = (): ReactElement => {
    const intl = useIntl();
    const { pathname, search } = useLocation();
    const searchCleaned = removeQueryParam(search, USER_MANUAL);
    const batteryHealthSearch = addQueryParam(searchCleaned, USER_MANUAL, 'details.batteryHealth');

    const formattedMessageValues = {
        b: (chunks: any) => <b>{chunks}</b>,
        a: (chunks: any) => <Link to={{ pathname, search: batteryHealthSearch }}>{chunks}</Link>,
    };
    return (
        <UserManualPage
            title={<FormattedMessage id="e4c.userManual.details.batteryHealthHistoricalView" />}
            footer={<FormattedMessage id="e4c.userManual.details.global.onlyAvailable" />}
            dataTestId="battery-health-historical-item"
        >
            <img
                alt={intl.formatMessage({ id: 'e4c.userManual.altText.batteryHealthHistoricalView' })}
                src={detailBatteryHealthHistoricalImage}
                width="900"
                height="600"
            />
            <UserManualSection
                title={<FormattedMessage id="e4c.userManual.details.batteryHealthHistoricalView.sectionTitle" />}
            >
                <FormattedMessage
                    id="e4c.userManual.details.batteryHealthHistoricalView.description"
                    values={formattedMessageValues}
                />
            </UserManualSection>
        </UserManualPage>
    );
};
export default BatteryHealthHistoricalItem;
